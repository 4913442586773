import type { CollaborationTabValues } from '../../../features/collaboration/components/Header';
import type { PreviousPageData } from '../../components/HeaderActionBar';
import { encodeObjectToBase64, removeEmoji } from '../strings';

export enum UrlHashTypes {
  LOCATION_DATA = 'LOCATION_DATA',
  COLLABORATION_ACTION = 'SIDEPANEL_ACTION', // leaving this name for now to avoid breaking changes
  SUMMARY_SHEET_ACTION = 'SUMMARY_SHEET_ACTION',
}
type SummarySheetActionData = {
  criterionAnswerId: number;
};

export type UrlHashValues = {
  [UrlHashTypes.LOCATION_DATA]: PreviousPageData;
  [UrlHashTypes.COLLABORATION_ACTION]: CollaborationTabValues;
  [UrlHashTypes.SUMMARY_SHEET_ACTION]: SummarySheetActionData;
};

export type UrlHashObject = Partial<Record<UrlHashTypes, any>>;

export class UrlHashBuilder {
  private urlHash: UrlHashObject;

  constructor() {
    this.urlHash = {};
  }

  public addLocationData(): UrlHashBuilder {
    const documentTitle = document.title;
    const locationData: PreviousPageData = {
      pathname: location.pathname,
      title: removeEmoji(documentTitle),
    };

    this.urlHash[UrlHashTypes.LOCATION_DATA] = locationData;
    return this;
  }

  // If you update this method, make sure to update hard encoded hash in magellan's sendgridService.sendMentionInCommentEmail
  public addCollaborationActionData(
    tabToOpen: CollaborationTabValues,
  ): UrlHashBuilder {
    this.urlHash[UrlHashTypes.COLLABORATION_ACTION] = tabToOpen;
    return this;
  }

  public addSummarySheetActionData(criterionAnswerId: number): UrlHashBuilder {
    this.urlHash[UrlHashTypes.SUMMARY_SHEET_ACTION] = {
      criterionAnswerId,
    };
    return this;
  }

  public build(): string {
    return encodeObjectToBase64(this.urlHash);
  }
}
