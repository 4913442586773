import { Box, ScrollArea, Stack } from '@mantine/core';

import { IconPlus } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';

import { Button } from '../../../../../../shared/components/UI/Button/Button';
import { EmptyPlaceholder } from '../../../../../../shared/components/UI/EmptyPlaceholder/EmptyPlaceholder';

import type { SummaryCriterion } from '../../../../../../shared/entities/SummaryCriterion';
import { useSummarySheetCriteria } from '../../hooks/useSummarySheetCriteria.hook';
import type { ValidationErrors } from '../../hooks/useSummarySheetForm.hook';
import { useSummarySheetForm } from '../../hooks/useSummarySheetForm.hook';
import { CriterionInput } from './CriterionInput';
import { Footer } from './Footer';

type CriteriaSidepanelContentProps = {
  tenderId: number;
  closePanel: () => void;
};

export function CriteriaSidepanelContent({
  tenderId,
  closePanel,
}: CriteriaSidepanelContentProps) {
  const {
    validationErrors,
    validateCriteria,
    hasErrors,
    clearValidationErrorForCriterion,
  } = useSummarySheetForm();

  const {
    criteria,
    dragContainerRef,
    isDragging,
    hasChanges,
    handleUpdateCriterion,
    handleDeleteCriterion,
    handleNewCriterion,
  } = useSummarySheetCriteria(clearValidationErrorForCriterion);

  return (
    <Stack pt={0} spacing={0} w="65vw" miw="856px" h="calc(100% - 48px)">
      <ScrollArea
        px="03"
        sx={{
          flexGrow: 2,
          overflowY: 'auto',
        }}
      >
        <CriteriaList
          criteria={criteria}
          dragContainerRef={dragContainerRef}
          isDragging={isDragging}
          handleUpdateCriterion={handleUpdateCriterion}
          handleDeleteCriterion={handleDeleteCriterion}
          handleNewCriterion={handleNewCriterion}
          errors={validationErrors}
        />
      </ScrollArea>
      <Footer
        validateCriteria={() => validateCriteria(criteria)}
        hasChanges={hasChanges}
        hasErrors={hasErrors}
        criteria={criteria}
        tenderId={tenderId}
        closePanel={closePanel}
      />
    </Stack>
  );
}

function CriteriaList({
  criteria,
  isDragging,
  dragContainerRef,
  handleUpdateCriterion,
  handleDeleteCriterion,
  handleNewCriterion,
  errors,
}: {
  criteria: SummaryCriterion[];
  isDragging: boolean;
  dragContainerRef: React.RefObject<HTMLDivElement>;
  handleUpdateCriterion: (criterion: SummaryCriterion) => void;
  handleDeleteCriterion: (criterion: SummaryCriterion) => void;
  handleNewCriterion: () => void;
  errors: Record<number, ValidationErrors>;
}) {
  const { t } = useTranslation('summarySheet');

  if (criteria.length === 0) {
    return (
      <>
        <Box ref={dragContainerRef} />
        <EmptyPlaceholder
          title={t('criteriaSidepanel.noCriteria')}
          subtitle={t('criteriaSidepanel.noCriteriaSubtitle')}
          actionsComponent={
            <Button onClick={() => handleNewCriterion()}>
              {t('criteriaSidepanel.addCriterion')}
            </Button>
          }
        />
      </>
    );
  }
  return (
    <Stack ref={dragContainerRef} spacing="03" pb="09">
      {criteria.map(criterion => (
        <CriterionInput
          criterion={criterion}
          key={criterion.id}
          isDragging={isDragging}
          onUpdateCriterion={handleUpdateCriterion}
          onDeleteCriterion={handleDeleteCriterion}
          errors={errors[criterion.id]}
        />
      ))}
      <Button
        key="button-add-criterion"
        id="no-drag"
        mt="02"
        variant="outline"
        size="sm"
        w="fit-content"
        leftIcon={<IconPlus />}
        onClick={() => handleNewCriterion()}
      >
        {t('criteriaSidepanel.addCriterion')}
      </Button>
    </Stack>
  );
}
