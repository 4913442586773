import { useMutation } from '@tanstack/react-query';

import {
  generateSummarySheet,
  getSummaryCriterionAnswers,
} from '../../../../../shared/api/magellan/summarySheet';
import type { GetSummaryCriteriaAnswersResponseDTO } from '../../../../../shared/api/magellan/summarySheet/dto';
import type { SummaryCriterion } from '../../../../../shared/entities/SummaryCriterion';
import { queryClient } from '../../../../../shared/infra/queryClient';
import { sanitizeCriteria } from '../utils/sanitizeCriteria';

type SummarySheetTriggerProps = {
  tenderId: number;
  criteria?: SummaryCriterion[];
};

export function useSummarySheetTrigger() {
  const { mutate } = useMutation({
    mutationFn: ({ tenderId, criteria }: SummarySheetTriggerProps) => {
      const sanitizedCriteria = criteria
        ? sanitizeCriteria(criteria)
        : undefined;
      return generateSummarySheet(tenderId, sanitizedCriteria);
    },
    onSuccess: (_data, { tenderId }) => markSummarySheetAsRunning(tenderId),
  });

  return { triggerSummarySheetGeneration: mutate };
}

function markSummarySheetAsRunning(tenderId: number) {
  const queryKey = [getSummaryCriterionAnswers.name, tenderId];
  return queryClient.setQueryData(
    queryKey,
    (prev: GetSummaryCriteriaAnswersResponseDTO) => {
      return { ...prev, summarySheetRAGStatus: 'RUNNING' };
    },
  );
}
