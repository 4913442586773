import { useState } from 'react';

import { Group, Image, Stack, Text } from '@mantine/core';

import { IconCheck } from '@tabler/icons-react';

import { Avatar } from '../../../../shared/components/UI/Avatar/Avatar';
import { Button } from '../../../../shared/components/UI/Button/Button';
import { TextArea } from '../../../../shared/components/UI/TextArea/TextArea';

import IAIConAlertCircle from '../../../../shared/assets/ai_icons/alert_circle.svg';
import { useActiveUserQuery } from '../../../../shared/hooks/useActiveUserQuery.hook';

const NOT_FOUND_ANSWER =
  'Nous n’avons pas trouvé la réponse dans les documents.';

type EditAnswerProps = {
  isEditing: boolean;
  initialManualAnswer?: string | null;
  setIsEditing: (isEditing: boolean) => void;
  handleManualAnswer: (manualAnswer: string) => void;
};
export const EditAnswer = ({
  handleManualAnswer,
  isEditing,
  initialManualAnswer,
  setIsEditing,
}: EditAnswerProps) => {
  return (
    <Stack spacing="03" px="05">
      {isEditing ? (
        <Text variant="xs" fw={500} c="gray.6">
          Ajoutez votre réponse
        </Text>
      ) : (
        <>
          <Text variant="xs" fw={500} c="gray.6">
            Réponse
          </Text>
          <Group spacing="02">
            <Image src={IAIConAlertCircle} mah={16} maw={16} />
            <Text variant="md" fw={400} c="gray.9">
              {NOT_FOUND_ANSWER}
            </Text>
          </Group>
        </>
      )}
      {isEditing ? (
        <ManualAnsweInput
          closeEditMode={() => setIsEditing(false)}
          initialManualAnswer={initialManualAnswer}
          handleManualAnswer={handleManualAnswer}
        />
      ) : (
        <Button w="fit-content" onClick={() => setIsEditing(true)}>
          Répondre manuellement
        </Button>
      )}
    </Stack>
  );
};
type ManualAnsweInputProps = {
  closeEditMode: () => void;
  initialManualAnswer?: string | null;
  handleManualAnswer: (manualAnswer: string) => void;
};
const ManualAnsweInput = ({
  closeEditMode,
  handleManualAnswer,
  initialManualAnswer,
}: ManualAnsweInputProps) => {
  const [manualAnswer, setManualAnswer] = useState<string>(
    initialManualAnswer || '',
  );
  const activeUserQuery = useActiveUserQuery();

  const { user: activeUser } = activeUserQuery.data;

  return (
    <Group spacing="04" align="flex-start" noWrap>
      <Avatar size="lg" radius="lg" color={activeUser.avatarColor}>
        {activeUser.firstName[0].toUpperCase() +
          activeUser.lastName[0].toUpperCase()}
      </Avatar>
      <Stack spacing="03" w="75%">
        <TextArea
          value={manualAnswer}
          onChange={(e: any) => setManualAnswer(e.currentTarget.value)}
          placeholder="Ajoutez manuellement une réponse pour garder une trace de vos analyses."
          variant="filled"
          size="xs"
          w="100%"
        />
        <Group spacing="02" onClick={() => handleManualAnswer(manualAnswer)}>
          <Button
            variant="default"
            size="sm"
            leftIcon={<IconCheck />}
            onClick={closeEditMode}
          >
            Enregistrer
          </Button>
          <Button variant="subtle" color="gray" onClick={closeEditMode}>
            Annuler
          </Button>
        </Group>
      </Stack>
    </Group>
  );
};
