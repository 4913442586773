import * as Sentry from '@sentry/react';
import { useMutation, useSuspenseQuery } from '@tanstack/react-query';

import { updateComment } from '../../../../../shared/api/magellan/comments';
import { getThreads } from '../../../../../shared/api/magellan/threads';
import { displayErrorNotification } from '../../../../../shared/components/notifications/errorNotification';
import type { RichTextJson } from '../../../../../shared/entities/Comment';
import { ThreadElementType } from '../../../../../shared/entities/Thread';
import { queryClient } from '../../../../../shared/infra/queryClient';

type UpdateCommentType = {
  commentId: number;
  content: RichTextJson;
};

export function useThreads(tenderId: number) {
  const queryKey = [getThreads.name, tenderId];
  const queryFn = async () =>
    getThreads(tenderId, ThreadElementType.INTERACTION);
  const { data: threads } = useSuspenseQuery({
    queryKey,
    queryFn,
  });

  const handleError = (error: Error, message: string) => {
    Sentry.captureException(error);
    displayErrorNotification('Erreur', message);

    throw Error(`Error on threads: ${message}`);
  };

  const refetch = () => queryClient.invalidateQueries({ queryKey });

  const updateCommentMutation = useMutation({
    mutationFn: ({ commentId, content }: UpdateCommentType) =>
      updateComment(commentId, content),
    onSuccess: refetch,
    onError: error => {
      handleError(
        error,
        "Une erreur a eu lieu lors de l'edition de votre commentaire",
      );
    },
  });

  const commentCounter =
    threads?.reduce((acc, thread) => acc + thread.comments.length, 0) || 0;

  return {
    threads,
    updateComment: (commentId: number, content: RichTextJson) =>
      updateCommentMutation.mutate({ commentId, content }),
    commentCounter,
  };
}
