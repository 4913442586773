import { Stack, Title } from '@mantine/core';

import { useTranslation } from 'react-i18next';

import { useActiveUserQuery } from '../../../../../shared/hooks/useActiveUserQuery.hook';
import { useUpdateUserMetadataMutation } from '../../../../../shared/hooks/useUpdateUserMetadataMutation.hook';
import { useNoticeContext } from '../../../contexts/Notice.provider';
import { useGetSummaryCriterionAnswers } from '../hooks/useGetSummaryCriterionAnswers.hook';
import { CriterionRow } from './CriterionRow';
import { Footer } from './Footer';
import { InfoMessage } from './InfoMessage';
import { OnTheFlyQuestion } from './OnTheFlyQuestion';

export const SummarySheetCard = () => {
  return (
    <Stack spacing="04" mb="10">
      <SummarySheet />
      <OnTheFlyQuestion />
      <Footer />
    </Stack>
  );
};

const SummarySheet = () => {
  const { t } = useTranslation('summarySheet');
  const { tender } = useNoticeContext();
  const summarySheetCriterionAnswersQuery = useGetSummaryCriterionAnswers(
    tender.id,
  );
  const activeUserQuery = useActiveUserQuery();
  const updateUserMetadataMutation = useUpdateUserMetadataMutation();

  const activeUser = activeUserQuery.data.user;
  const shouldShowInfoMessage =
    activeUser.metadata?.isUnreadableDocWarningRead !== true;

  return (
    <Stack
      p="05"
      bg="white"
      sx={theme => ({
        border: `1px solid ${theme.colors.gray[1]}`,
        borderRadius: theme.radius.md,
        boxShadow: theme.shadows.xs,
      })}
    >
      <Title order={5}>{t('title')}</Title>

      <InfoMessage
        isVisible={shouldShowInfoMessage}
        onGotItClick={() => {
          updateUserMetadataMutation.mutate({
            userId: activeUser.id,
            metadata: { isUnreadableDocWarningRead: true },
          });
        }}
      />

      <Stack spacing="02">
        {summarySheetCriterionAnswersQuery.data.criteriaAnswers.map(
          criterion => (
            <CriterionRow key={criterion.id} criterion={criterion} />
          ),
        )}
      </Stack>
    </Stack>
  );
};
