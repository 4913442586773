import { Box, Flex, Select, useMantineTheme } from '@mantine/core';

import { IconChevronDown, IconEye } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Button } from '../UI/Button/Button';

import type { DataPointSource } from '../../entities/DataPointMeta';
import type { Document } from '../../entities/Document';
import {
  DocumentPreview,
  SUPPORTED_OFFICE_EXTENSIONS,
} from '../DocumentPreview/DocumentPreview';
import { DownloadFileButton } from '../DownloadFileButton';
import { SourceExtractPreview } from './SourceExtractPreview';
import { formatSourceLabel } from './SourcesSection';

export const DOCUMENT_SIDE_PANEL_WIDTH = 800;

export function Header({
  document,
  source,
  sources,
  onSourceChange,
}: {
  document: Document;
  source: DataPointSource;
  sources: DataPointSource[];
  onSourceChange: (source: DataPointSource) => void;
}) {
  const initialSelectedSourceIndex = sources.findIndex(
    ({ docFilePath, pageNumber }) =>
      docFilePath === source.docFilePath && pageNumber === source.pageNumber,
  );

  const selectData = sources.map((source, index) => ({
    value: index.toString(),
    label: formatSourceLabel(source),
  }));

  function handleSourceChange(value: string) {
    const source = sources[parseInt(value)];
    onSourceChange(source);
  }

  return (
    <Flex justify="space-between" w="100%">
      <Flex justify="center" align="center" w="100%">
        <Select
          styles={theme => ({
            root: { minWidth: '450px' },
            input: {
              border: 'none',
              textAlign: 'center',
              fontWeight: 500,
              color: theme.colors.gray[9],
              fontSize: theme.fontSizes.sm,
            },
            dropdown: { minWidth: 'fit-content' },
            item: { wordSpacing: 'nowrap' },
          })}
          rightSection={<IconChevronDown size={16} />}
          value={initialSelectedSourceIndex.toString()}
          data={selectData}
          onChange={handleSourceChange}
        />
      </Flex>

      <DownloadFileButton
        fileURL={document.fileURL}
        prettyName={document.prettyName}
      />
    </Flex>
  );
}

export function Content({
  tenderId,
  source,
  document,
  onClose,
}: {
  tenderId: number;
  source: DataPointSource;
  document: Document;
  onClose: () => void;
}) {
  const theme = useMantineTheme();
  const { t } = useTranslation('notice');

  const extension = document.fileURL.split('.').pop()?.toLowerCase() || '';
  const isDocX = SUPPORTED_OFFICE_EXTENSIONS.includes(extension);

  return (
    <Box px="04" w={DOCUMENT_SIDE_PANEL_WIDTH} h="100%">
      {isDocX ? (
        <>
          <SourceExtractPreview extract={source.originalChunkExtract} />
          <Button
            variant="default"
            color="primary"
            mt="04"
            size="sm"
            component={Link}
            to={`/notices/${tenderId}?tab=documents`}
            onClick={onClose}
            leftIcon={<IconEye color={theme.colors.primary[7]} />}
          >
            {t('documents.seeFullDocument')}
          </Button>
        </>
      ) : (
        <DocumentPreview
          search={source.originalChunkExtract}
          initialPage={source.pageNumber ?? 1}
          fileURL={document.fileURL}
          prettyName={document.prettyName}
        />
      )}
    </Box>
  );
}
