import { useEffect, useState } from 'react';

import { Group, Loader, Text } from '@mantine/core';

import { IconCheck } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';

export enum LoadingState {
  WAITING = 'waiting',
  LOADING = 'loading',
  SUCCESS = 'success',
}
type LoaderNotifProps = {
  loadingState: LoadingState.WAITING | LoadingState.LOADING;
};

export const LoaderNotif = ({ loadingState }: LoaderNotifProps) => {
  const { t } = useTranslation('summarySheet');
  const [displayState, setDisplayState] = useState<LoadingState>(loadingState);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (loadingState === LoadingState.LOADING) {
      setVisible(true);
      setDisplayState(LoadingState.LOADING);
    } else if (
      loadingState === LoadingState.WAITING &&
      displayState === LoadingState.LOADING
    ) {
      setDisplayState(LoadingState.SUCCESS);
      setTimeout(() => {
        setVisible(false);
      }, 1000);
    }
  }, [displayState, loadingState]);

  const loadingIcon =
    displayState === LoadingState.LOADING ? (
      <Loader size="xs" />
    ) : (
      <IconCheck size={16} />
    );
  const loadingText =
    displayState === LoadingState.LOADING
      ? t('loader.waiting')
      : t('loader.success');

  return (
    <Group
      noWrap
      py="02"
      pl="03"
      pr="04"
      w="fit-content"
      spacing="02"
      display={visible ? 'flex' : 'none'}
      sx={theme => ({
        border: `1px solid ${theme.colors.gray[1]}`,
        borderRadius: theme.radius.xl,
        boxShadow: theme.shadows.sm,
      })}
    >
      {loadingIcon}
      <Text variant="xs" fw={500} c="gray.5">
        {loadingText}
      </Text>
    </Group>
  );
};
