import type { ReactNode } from 'react';

import { Stack, Text, useMantineTheme } from '@mantine/core';

type TenderDataDisplayProps = {
  statTitle: string | ReactNode;
  statValue: string | ReactNode;
};

export function TenderDataDisplay({
  statTitle,
  statValue,
}: TenderDataDisplayProps) {
  const theme = useMantineTheme();
  const outinedVariant = {
    borderRadius: theme.radius.md,
    background: 'white',
    border: `1px solid ${theme.colors.gray[1]}`,
    shadow: theme.shadows.xs,
    padding: theme.spacing['02'],
    paddingLeft: theme.spacing['03'],
    paddingRight: theme.spacing['04'],
  };

  return (
    <Stack spacing="0" sx={outinedVariant}>
      <RenderStatTitle statTitle={statTitle} />
      <Text variant="md" fw="400" c="gray.9">
        {statValue}
      </Text>
    </Stack>
  );
}

type RenderStatTitleProps = {
  statTitle: string | ReactNode;
};

const RenderStatTitle = ({ statTitle }: RenderStatTitleProps) => {
  if (typeof statTitle === 'string') {
    return (
      <Text
        variant="xs"
        fw="500"
        c="gray.6"
        sx={{
          whiteSpace: 'nowrap',
        }}
      >
        {statTitle}
      </Text>
    );
  }
  return statTitle;
};
