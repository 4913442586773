import { useEffect, useRef, useState } from 'react';

import {
  Container,
  Divider,
  FocusTrap,
  Grid,
  ScrollArea,
  Stack,
} from '@mantine/core';
import { useForm } from '@mantine/form';

import { IconWand } from '@tabler/icons-react';
// importing locale as required by mantine dateProvider:
// https://mantine.dev/dates/dates-provider/
import 'dayjs/locale/fr';
import { useNavigate } from 'react-router-dom';

import { Loader } from '../../shared/components/UI/Loader/Loader';

import { AdvancedFields } from '../../features/streams/components/forms/components/AdvancedFields';
import { BackNavButton } from '../../features/streams/components/forms/components/BackNavButton';
import { BasicFields } from '../../features/streams/components/forms/components/BasicFields';
import { SearchSidebar } from '../../features/streams/components/forms/components/SearchSidebar';
import { useStreamsActions } from '../../features/streams/components/forms/hooks/useStreamsActions.hook';
import { useStreamsContext } from '../../features/streams/contexts/Streams.provider';
import { searchTendersPreview } from '../../shared/api/magellan/tender';
import Stream from '../../shared/entities/Stream';
import type { StreamFormValues } from '../../shared/entities/StreamFilterSettings';

export default function CreateStreamPage() {
  const { createStream } = useStreamsActions();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [resultsCount, setResultsCount] = useState<number>();
  const streamsContext = useStreamsContext();
  const navigate = useNavigate();
  const searchPreviewRequestIdRef = useRef<number>(0);
  const form = useForm<StreamFormValues>({
    validateInputOnChange: true,
    initialValues: {
      name: '',
      status: ['CLOSED', 'OPEN'],
      keywords: [],
      includedCPVs: [],
      excludedCPVs: [],
      includeTendersWithoutCPV: true,
      includeTendersWithoutDepartment: true,
    },
    validate: {
      status: value =>
        value?.length && value?.length > 0
          ? undefined
          : 'Veuillez sélectionner au moins un statut',
    },
  });

  // Using useRef because useCallback, useEffect and useMemo require the form object as a dependency. And form.isValid() mutates the form object, trigering an infinite loop.
  const formRef = useRef(form);

  // tracking form changes be able to use the formRef in the useEffect below without having to pass the entire form object as a dependency
  useEffect(() => {
    formRef.current = form;
  }, [form]);

  useEffect(() => {
    searchPreviewRequestIdRef.current += 1;
    const requestId = searchPreviewRequestIdRef.current;

    const triggerSearchPreview = async () => {
      setIsLoading(true);
      const {
        name: _name,
        streamSectionId: _streamSectionId,
        ...stripedValues
      } = form.values;

      const resultsCount = await searchTendersPreview({
        ...stripedValues,
      });
      // Only update the results if the request is the latest one
      if (requestId !== searchPreviewRequestIdRef.current) return;
      setResultsCount(resultsCount);
      setIsLoading(false);
    };

    // Adding a debounce to minimize the API call on every small changes
    const timerId = setTimeout(() => {
      if (formRef.current.isValid()) {
        triggerSearchPreview();
      } else {
        setResultsCount(0);
      }
    }, 500);

    // Clean up function to cancel the timeout when component unmounts or values change
    return () => {
      clearTimeout(timerId);
    };
  }, [form.values]);

  if (streamsContext.status === 'loading') {
    return <Loader title="Chargement..." />;
  }

  const handleSubmit = async (values: StreamFormValues) => {
    const stream = new Stream();
    const { name, streamSectionId, ...stripedValues } = values;

    stream.name = name || 'Mon Flux (à renommer)';
    stream.filterSettings = stripedValues;
    stream.streamSectionId =
      streamSectionId === -1 ? undefined : streamSectionId;

    const createdStream = await createStream(stream);
    if (createdStream) {
      navigate(`/flux/${createdStream.id}`);
    }
  };

  return (
    <Container h="100%" w="100%" maw="1500px" fluid bg="white">
      <ScrollArea type="never" h="100%">
        <form onSubmit={form.onSubmit(handleSubmit)}>
          <Grid h="100%" w="100%" p={40} bg="white">
            <FocusTrap active>
              {/* Form Column */}
              <Grid.Col span={7}>
                <Stack spacing={32} h="100%" w="100%" pb="40px">
                  <BackNavButton
                    isFormDirty={form.isDirty()}
                    isFormValid={form.isValid()}
                    onSubmit={() => form.onSubmit(handleSubmit)}
                  />
                  <BasicFields form={form} />
                  <Divider size={1} />
                  <AdvancedFields form={form} />
                </Stack>
              </Grid.Col>
              {/* Submit Column */}
              <Grid.Col span={4} offset={1}>
                <SearchSidebar
                  isValid={form.isValid()}
                  isLoading={isLoading}
                  previewNumberOfResults={resultsCount}
                  buttonIcon={<IconWand size={18} />}
                  buttonText="Créer mon flux"
                />
              </Grid.Col>
            </FocusTrap>
          </Grid>
        </form>
      </ScrollArea>
    </Container>
  );
}
