import {
  type UseSuspenseQueryResult,
  useSuspenseQuery,
} from '@tanstack/react-query';

import { getCanGenerateSummarySheet } from '../../../../../shared/api/magellan/summarySheet';
import type { NotGeneratableReason } from '../../../../../shared/api/magellan/summarySheet/dto';

type CanGenerateSummarySheetType = {
  isGeneratable: boolean;
  error?: NotGeneratableReason;
};

export function useCanGenerateSummaryCriterionAnswers(
  tenderId: number,
): UseSuspenseQueryResult<CanGenerateSummarySheetType> {
  const queryKey = [getCanGenerateSummarySheet.name, tenderId];
  return useSuspenseQuery({
    queryKey,
    queryFn: () => getCanGenerateSummarySheet(tenderId),
  });
}
