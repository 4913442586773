import { useMutation, useQueryClient } from '@tanstack/react-query';
import posthog from 'posthog-js';
import { useNavigate } from 'react-router-dom';
import { signOut } from 'supertokens-auth-react/recipe/emailpassword';

export function useSignoutMutation() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  return useMutation({
    mutationFn: async () => await signOut(),
    onSuccess: () => {
      posthog.reset();
      navigate('/auth');
      queryClient.resetQueries();
    },
  });
}
