import * as Sentry from '@sentry/react';
import { useSuspenseQuery } from '@tanstack/react-query';
import { useSessionContext } from 'supertokens-auth-react/recipe/session';

import { getActiveUser } from '../api/magellan/users';

export function useActiveUserQuery() {
  const session = useSessionContext();

  const activeUserQuery = useSuspenseQuery({
    queryKey: [getActiveUser.name],
    queryFn: getActiveUser,
  });

  if (!activeUserQuery.data.user) {
    const error = new Error(`No active user found`);
    Sentry.withScope(scope => {
      scope.setExtra('session', session);
      scope.setExtra('activeUser', activeUserQuery.data.user);
      Sentry.captureException(error);
    });
    throw error;
  }

  return activeUserQuery;
}
