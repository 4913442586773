import { Group, Skeleton, Stack, Text } from '@mantine/core';

import { useTranslation } from 'react-i18next';

import { Loader } from '../../../../../shared/components/UI/Loader/Loader';

export const LoadingCard = () => {
  const { t } = useTranslation('summarySheet');
  return (
    <Stack
      spacing="03"
      p="05"
      mb="10"
      bg="white"
      sx={theme => ({
        border: `1px solid ${theme.colors.gray[1]}`,
        borderRadius: theme.radius.md,
        boxShadow: theme.shadows.xs,
      })}
    >
      <Text
        variant="sm"
        pb="03"
        c="gray.6"
        w="100%"
        sx={theme => ({
          borderBottom: `1px solid ${theme.colors.gray[1]}`,
        })}
      >
        {t('loadingCard.title')}
      </Text>
      <Text variant="sm" c="gray.4" sx={{ whiteSpace: 'pre-wrap' }}>
        {t('loadingCard.subtitle')}
      </Text>
      <Group noWrap p="03" spacing="03">
        <Loader loaderProps={{ size: 'md', color: 'gray.4' }} />
        <Stack spacing="02" w="100%">
          <Skeleton height="6px" width="200px" />
          <Skeleton height="6px" width="100px" />
        </Stack>
      </Group>
    </Stack>
  );
};
