import { Group, Image, Text } from '@mantine/core';
import type { MRT_Cell } from 'mantine-react-table';

import AIIconSparkle from '../../../../../../shared/assets/ai_icons/sparkle.svg';
import { formatCurrency } from '../../../../../../shared/utils/curencies';

export type AmountCellData = {
  amount: number;
  isLlmEnriched: boolean;
};

type AmountCellProps = {
  cell: MRT_Cell<any>;
};

export function AmountCell({ cell }: AmountCellProps) {
  const { amount, isLlmEnriched } = cell.getValue<AmountCellData>();
  if (isLlmEnriched) {
    return <LlmAmount amount={amount} />;
  }
  return <Text>{formatCurrency(amount) || '-'}</Text>;
}

const LlmAmount = ({ amount }: { amount: number }) => {
  return (
    <Group spacing="01">
      <Text
        variant="sm"
        fw={500}
        sx={theme => ({
          background: theme.other.gradients.aiHorizontal,
          backgroundClip: 'text',
          color: 'transparent',
        })}
      >
        {formatCurrency(amount)}
      </Text>
      <Image src={AIIconSparkle} alt="llm" maw={16} />
    </Group>
  );
};
