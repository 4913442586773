import {
  upsertInteractionWithDecisionStatus,
  upsertInteractionWithRenewalDecisionStatus,
} from '../api/magellan/interaction';
import {
  upsertInteractionWithNogo,
  upsertInteractionWithResults,
} from '../api/magellan/tender';
import {
  type DecisionRenewalStatus,
  DecisionStatus,
} from '../entities/Interaction';

export type StatusType =
  | {
      type: 'DecisionStatus';
      value: DecisionStatus;
    }
  | {
      type: 'DecisionRenewalStatus';
      value: DecisionRenewalStatus;
    };

export function upsertDecision({
  tenderId,
  status,
  reason,
  streamId,
  winningAmount,
}: {
  tenderId: number;
  status: StatusType;
  reason?: string;
  streamId?: number;
  winningAmount?: number;
}) {
  if (status.type !== 'DecisionStatus')
    return upsertInteractionWithRenewalDecisionStatus(tenderId, status.value);

  switch (status.value) {
    case DecisionStatus.NOGO:
      if (!reason) {
        throw new Error('No reason provided for nogo');
      }
      return upsertInteractionWithNogo(tenderId, reason);
    case DecisionStatus.LOSS:
      if (!reason) {
        throw new Error('No reason provided for a loss');
      }
      return upsertInteractionWithResults(tenderId, reason, status.value);
    case DecisionStatus.WIN:
      if (!reason) {
        throw new Error('No reason provided for a win');
      }
      return upsertInteractionWithResults(
        tenderId,
        reason,
        status.value,
        winningAmount,
      );

    default:
      return upsertInteractionWithDecisionStatus(
        tenderId,
        status.value,
        streamId,
      );
  }
}
