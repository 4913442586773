import type { DecisionLog } from '../../../entities/DecisionLog';
import type {
  DecisionStatus,
  Interaction,
} from '../../../entities/Interaction';
import type {
  Filters,
  StreamFilterSettings,
} from '../../../entities/StreamFilterSettings';
import { magellanClient } from '../../../infra/axios';
import type {
  FindOneTenderResponseDTO,
  IsInstantAnalysisAnalysableResponseDTO,
  SearchTenderDTO,
  UpdateOwnerResponseDTO,
} from './dto';

export async function getTender(id: number): Promise<FindOneTenderResponseDTO> {
  const response = await magellanClient.get<FindOneTenderResponseDTO>(
    `/tenders/${id}`,
  );
  return response.data;
}

export async function searchTenders(
  filter: Filters,
  skip: number,
  take: number,
  withDecision: boolean,
): Promise<SearchTenderDTO> {
  const streamFilterSettings: StreamFilterSettings = {
    ...filter,
    skip,
    take,
    withDecision,
  };
  const response = await magellanClient.post<SearchTenderDTO>(
    '/tenders/search',
    streamFilterSettings,
  );
  return response.data;
}

export async function searchTendersPreview(
  streamFilterSettings: Filters,
): Promise<number> {
  const response = await magellanClient.post<{ count: number }>(
    '/tenders/searchPreview',
    streamFilterSettings,
  );
  return response.data.count;
}
export async function upsertInteractionWithNogo(
  tenderId: number,
  reason: string,
): Promise<Interaction> {
  const response = await magellanClient.post<Interaction>(
    `tenders/${tenderId}/interactions/nogo`,
    {
      reason,
    },
  );

  return response.data;
}

export async function upsertInteractionWithResults(
  tenderId: number,
  reason: string,
  decisionStatus: DecisionStatus.WIN | DecisionStatus.LOSS,
  winningAmount?: number,
): Promise<Interaction> {
  const response = await magellanClient.post<Interaction>(
    `tenders/${tenderId}/interactions/result`,
    {
      decisionStatus,
      reason,
      winningAmount,
    },
  );

  return response.data;
}

export async function upsertInteractionWihNote(
  tenderId: number,
  note: string,
): Promise<Interaction> {
  const response = await magellanClient.post<Interaction>(
    `tenders/${tenderId}/interactions/note`,
    {
      note,
    },
  );

  return response.data;
}

export async function updateInteractionOwner(
  userId: string,
  tenderId: number,
): Promise<UpdateOwnerResponseDTO> {
  const response = await magellanClient.post<UpdateOwnerResponseDTO>(
    `tenders/${tenderId}/interactions/owner`,
    {
      userId,
    },
  );
  return response.data;
}

export async function getTenderIsAnalysable(
  tenderId: number,
): Promise<IsInstantAnalysisAnalysableResponseDTO> {
  const response =
    await magellanClient.get<IsInstantAnalysisAnalysableResponseDTO>(
      `/tenders/${tenderId}/instantAnalysis/isAnalyzable`,
    );

  return response.data;
}

export async function requestDCE(tenderId: number): Promise<void> {
  await magellanClient.post(`/tenders/${tenderId}/interactions/dceRequest`);
}

export async function triggerInstantAnalysisWithQuestionsSets(
  tenderId: number,
  questionsSetsIds: number[],
): Promise<number> {
  try {
    const response = await magellanClient.post(
      `/tenders/${tenderId}/instantAnalysis/trigger`,
      {
        questionsSetsIds,
      },
    );
    return response.status;
  } catch (e: any) {
    if (e.response?.status === 422) {
      return 422;
    } else {
      throw e;
    }
  }
}

export async function triggerOnTheFlyQuestionAnalysis(
  tenderId: number,
  question: string,
): Promise<number> {
  try {
    const response = await magellanClient.post(
      `/tenders/${tenderId}/instantAnalysis/triggerOnTheFlyQuestion`,
      {
        question,
      },
    );
    return response.status;
  } catch (e: any) {
    if (e.response?.status === 422) {
      return 422;
    } else {
      throw e;
    }
  }
}

export async function getDecisionLogs(
  tenderId: number,
): Promise<DecisionLog[]> {
  const response = await magellanClient.get<DecisionLog[]>(
    `/tenders/${tenderId}/logDecisions`,
  );

  return response.data;
}
