import { Skeleton, Stack } from '@mantine/core';

export function TabLoader() {
  return (
    <Stack
      p="07"
      mx="07"
      mb="05"
      mih="80%"
      sx={theme => ({
        border: `1px solid ${theme.colors.gray[1]}`,
        borderRadius: theme.radius.md,
        boxShadow: theme.shadows.xs,
      })}
    >
      <Skeleton h="20px" w="100%" />
      <Skeleton h="40px" w="100%" />
      <Skeleton h="100px" w="100%" />
    </Stack>
  );
}
