import { useMutation, useQueryClient } from '@tanstack/react-query';

import { getActiveUser, updateUserMetadata } from '../api/magellan/users';
import type { UserMetadata } from '../entities/User';

export function useUpdateUserMetadataMutation() {
  const queryClient = useQueryClient();

  const mutationFn = async ({
    userId,
    metadata,
  }: {
    userId: string;
    metadata: UserMetadata;
  }) => await updateUserMetadata(userId, metadata);

  return useMutation({
    mutationFn,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [getActiveUser.name] });
    },
  });
}
