import { Box, Text, ThemeIcon, useMantineTheme } from '@mantine/core';
import { useDisclosure, useHover } from '@mantine/hooks';

import { IconTrash } from '@tabler/icons-react';

import type {
  JudgementCriterion,
  requirementEvaluation,
} from '../../../../shared/entities/BidResponse';
import type { DataPointSource } from '../../../../shared/entities/DataPointMeta';
import { BidRequirementCell } from './Cells/BidRequirementCell';
import { RequirementEvaluationCell } from './Cells/RequirementEvaluationCell';
import { DeletionModal } from './DeletionModal';

type RequirementEvaluationRowProps = {
  index: number;
  bidResponseId: number;
  requirementEvaluation: requirementEvaluation;
  judgementCriteria: JudgementCriterion[];
  proposalVersionId: number;
};
export const RequirementEvaluationRow = ({
  requirementEvaluation,
  bidResponseId,
  index,
  judgementCriteria,
  proposalVersionId,
}: RequirementEvaluationRowProps) => {
  const theme = useMantineTheme();
  const { hovered, ref } = useHover<HTMLTableRowElement>();
  const tdStyle = {
    borderBottom: `1px solid ${theme.colors.gray[1]}`,
    background: hovered ? theme.colors.gray[0] : 'white',
    padding: theme.spacing['03'],
  };
  const source: DataPointSource = {
    docFilePath: requirementEvaluation.bidRequirement.source,
    originalChunkExtract:
      requirementEvaluation.bidRequirement.originalChunkExtract,
    pageNumber: requirementEvaluation.bidRequirement.pageNumber || null,
  };
  const [opened, { open, close }] = useDisclosure(false);

  return (
    <Box component="tr" ref={ref}>
      <DeletionModal
        opened={opened}
        close={close}
        bidRequirementId={requirementEvaluation.bidRequirement.id}
        bidResponseId={bidResponseId}
        proposalVersionId={proposalVersionId}
      />
      <td style={{ ...tdStyle, width: '20px' }}>
        {hovered ? (
          <ThemeIcon
            color="red.5"
            variant="light"
            size="sm"
            p="00"
            onClick={open}
            sx={{
              border: 'none',
              background: 'transparent',
              ':hover': {
                cursor: 'pointer',
                background: theme.colors.gray[2],
                borderRadius: theme.radius.sm,
                color: 'red.7',
              },
            }}
          >
            <IconTrash />
          </ThemeIcon>
        ) : (
          <Text variant="sm" fw={400} c="gray.5" pl="01">
            {index + 1}
          </Text>
        )}
      </td>
      <td style={{ ...tdStyle, width: '36%' }}>
        <BidRequirementCell
          bidRequirement={requirementEvaluation.bidRequirement}
          judgmentCriteria={judgementCriteria}
          source={source}
        />
      </td>
      <td style={{ ...tdStyle, alignContent: 'flex-start' }}>
        <RequirementEvaluationCell
          requirementEvaluation={requirementEvaluation}
        />
      </td>
    </Box>
  );
};
