import { useSuspenseQuery } from '@tanstack/react-query';

import { getTender } from '../api/magellan/tender';
import type { FindOneTenderResponseDTO } from '../api/magellan/tender/dto';

export function useTenderQuery(tenderId: number) {
  return useSuspenseQuery<FindOneTenderResponseDTO>({
    queryKey: [getTender.name, tenderId],
    queryFn: async () => await getTender(tenderId),
  });
}
