import {
  ActionIcon,
  Box,
  Group,
  Stack,
  Text,
  ThemeIcon,
  useMantineTheme,
} from '@mantine/core';

import {
  IconGripVertical,
  IconQuestionMark,
  IconSquareCheckFilled,
  IconTrash,
  IconX,
} from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';

import { Select } from '../../../../../../shared/components/UI/Select/Select';
import { TextArea } from '../../../../../../shared/components/UI/TextArea/TextArea';
import { TextInput } from '../../../../../../shared/components/UI/TextInput/TextInput';
import { Tooltip } from '../../../../../../shared/components/UI/Tooltip/Tooltip';

import type { SummaryCriterion } from '../../../../../../shared/entities/SummaryCriterion';
import type { ValidationErrors } from '../../hooks/useSummarySheetForm.hook';

type CriterionInputProps = {
  criterion: SummaryCriterion;
  onUpdateCriterion: (criterion: SummaryCriterion) => void;
  onDeleteCriterion: (criterion: SummaryCriterion) => void;
  isDragging: boolean;
  errors?: ValidationErrors;
};

export function CriterionInput({
  criterion,
  onUpdateCriterion,
  onDeleteCriterion,
  isDragging,
  errors,
}: CriterionInputProps) {
  const theme = useMantineTheme();
  const { t } = useTranslation(['summarySheet']);
  const { title, question, examples, llmAnswerType } = criterion;

  return (
    <Group
      data-criterion-id={criterion.id}
      noWrap
      spacing="02"
      p="02"
      align="flex-start"
      bg="white"
      sx={theme => ({
        borderRadius: theme.radius.md,
        border: '1px solid transparent',
        borderColor: isDragging ? theme.colors.gray[1] : 'transparent',
        boxShadow: isDragging ? theme.shadows.md : 'none',
      })}
    >
      <Group noWrap spacing="01" pt="00" id={`${criterion.id}-drag`}>
        <ActionIcon size="21px" onClick={() => onDeleteCriterion(criterion)}>
          <IconTrash />
        </ActionIcon>
        <ActionIcon size="21px" id="drag-handle">
          <IconGripVertical />
        </ActionIcon>
      </Group>
      <Stack w="100%" spacing="02" align="flex-start">
        <TextInput
          w="100%"
          variant="white"
          placeholder={t('criteriaSidepanel.criterionInputPlaceholders.title')}
          pt={0}
          size="xs"
          fw={500}
          value={title}
          onChange={e => {
            onUpdateCriterion({ ...criterion, title: e.target.value });
          }}
          onKeyDown={e => {
            if (e.key === 'Enter') {
              e.preventDefault();
              e.currentTarget.nextElementSibling
                ?.querySelector('input')
                ?.focus();
            }
          }}
          error={errors?.title}
        />
        <Box w="100%" sx={{ position: 'relative' }}>
          <TextInput
            w="100%"
            variant="white"
            size="xs"
            fw={500}
            fs={theme.fontSizes.xs}
            placeholder={t(
              'criteriaSidepanel.criterionInputPlaceholders.question',
            )}
            value={question}
            onChange={e => {
              onUpdateCriterion({ ...criterion, question: e.target.value });
            }}
            onKeyDown={e => {
              if (e.key === 'Enter') {
                e.preventDefault();
                e.currentTarget.nextElementSibling
                  ?.querySelector('textarea')
                  ?.focus();
              }
            }}
            error={errors?.question}
          />
          <QuestionTooltip question={question} />
        </Box>
        <TextArea
          w="100%"
          variant="white"
          size="xs"
          fw={500}
          fs={theme.fontSizes.xs}
          placeholder={t(
            'criteriaSidepanel.criterionInputPlaceholders.example',
          )}
          maxRows={undefined}
          value={examples || undefined}
          onChange={e => {
            onUpdateCriterion({ ...criterion, examples: e.target.value });
          }}
          error={errors?.examples}
        />
        <Select
          size="xs"
          pb="00"
          data={[
            {
              label: t('criteriaSidepanel.answerType.longAnswer'),
              value: 'LONG_ANSWER',
            },
            {
              label: t('criteriaSidepanel.answerType.shortAnswer'),
              value: 'SHORT_ANSWER',
            },
          ]}
          defaultValue={t('criteriaSidepanel.answerType.longAnswer')}
          fs={theme.fontSizes.xs}
          value={llmAnswerType}
          onChange={e => {
            onUpdateCriterion({
              ...criterion,
              llmAnswerType: e as SummaryCriterion['llmAnswerType'],
            });
          }}
        />
      </Stack>
    </Group>
  );
}

type QuestionTooltipProps = {
  question: string;
};

function QuestionTooltip({ question }: QuestionTooltipProps) {
  const { t } = useTranslation(['summarySheet']);
  const contentElement = (
    <Stack p="04" spacing="04">
      <Stack spacing="02">
        <QuestionTooltipContent
          type="bad"
          question={t('criteriaSidepanel.tooltips.badQuestion1')}
        />
        <QuestionTooltipContent
          type="good"
          question={t('criteriaSidepanel.tooltips.goodQuestion1')}
        />
      </Stack>
      <Stack spacing="02">
        <QuestionTooltipContent
          type="bad"
          question={t('criteriaSidepanel.tooltips.badQuestion2')}
        />
        <QuestionTooltipContent
          type="good"
          question={t('criteriaSidepanel.tooltips.goodQuestion2')}
        />
      </Stack>
    </Stack>
  );
  return (
    <Group
      h="100%"
      noWrap
      px="02"
      pt="01"
      spacing="02"
      align="flex-start"
      sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        pointerEvents: 'none',
      }}
    >
      <Text
        variant="xs"
        fw={500}
        sx={{
          width: 'auto',
          visibility: 'hidden',
          pointerEvents: 'none',
        }}
      >
        {question || t('criteriaSidepanel.criterionInputPlaceholders.question')}
      </Text>

      <Tooltip content={contentElement} sx={{ pointerEvents: 'auto' }}>
        <ThemeIcon variant="outline" radius="xl" color="primary.6" size={16}>
          <IconQuestionMark stroke={2} />
        </ThemeIcon>
      </Tooltip>
    </Group>
  );
}

type QuestionTooltipContentProps = {
  type: 'bad' | 'good';
  question: string;
};

function QuestionTooltipContent({
  type,
  question,
}: QuestionTooltipContentProps) {
  const goodIcon = (
    <Box c="green.6">
      <IconSquareCheckFilled size={16} />
    </Box>
  );
  const badIcon = (
    <Box c="red.6">
      <IconX size={16} />
    </Box>
  );
  return (
    <Group noWrap spacing="01" align="flex-start">
      {type === 'good' ? goodIcon : badIcon}
      <Text variant="xs" fw={500} pt="00">
        {question}
      </Text>
    </Group>
  );
}
