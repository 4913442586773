import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { deleteComment } from '../../../../../shared/api/magellan/comments';
import { getSummaryCriterionDetail } from '../../../../../shared/api/magellan/summarySheet';
import { getThreads } from '../../../../../shared/api/magellan/threads';
import { queryClient } from '../../../../../shared/infra/queryClient';
import { handleError } from '../utils/handleCommentError';

type DeleteCommentType = {
  commentId: number;
};

export function useDeleteCommentMutation(tenderId?: number) {
  const { t } = useTranslation('collaboration');
  return useMutation({
    mutationFn: ({ commentId }: DeleteCommentType) => deleteComment(commentId),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [getThreads.name, tenderId] });
      queryClient.invalidateQueries({
        queryKey: [getSummaryCriterionDetail.name, tenderId],
      });
    },
    onError: error => {
      handleError(error, t('errorMessages.deleteComment'));
    },
  });
}
