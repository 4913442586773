import { Center, Stack, Title } from '@mantine/core';

import { useTranslation } from 'react-i18next';

import { DownloadFileButton } from '../DownloadFileButton';

export function FileNotSupported({
  fileURL,
  prettyName,
}: {
  fileURL: string;
  prettyName: string;
}) {
  const { t } = useTranslation();

  return (
    <Center
      h="100%"
      mx="03"
      p="08"
      bg="linear-gradient(180deg, #F7FBFF 34%, rgba(247, 251, 255, 0) 100%)"
      sx={theme => ({ borderRadius: theme.radius.md, flexGrow: 2 })}
    >
      <Stack align="center" spacing="05">
        <Title align="center" order={4} c="gray.9">
          {t('documents.noPreviewAvailable')}
        </Title>
        <DownloadFileButton fileURL={fileURL} prettyName={prettyName} />
      </Stack>
    </Center>
  );
}
