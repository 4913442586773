import { useState } from 'react';

import { useTranslation } from 'react-i18next';

import type { SummaryCriterion } from '../../../../../shared/entities/SummaryCriterion';

export type ValidationErrors = {
  title?: string;
  question?: string;
  examples?: string;
};

export function useSummarySheetForm() {
  const [validationErrors, setValidationErrors] = useState<
    Record<number, ValidationErrors>
  >({});
  const { t } = useTranslation(['summarySheet']);
  const hasErrors = Object.values(validationErrors).length > 0;

  const validateCriteria = (criteria: SummaryCriterion[]) => {
    const errors: Record<number, ValidationErrors> = {};
    let hasErrors = false;

    criteria.forEach(criterion => {
      const criterionErrors: ValidationErrors = {};

      if (!criterion.title?.trim()) {
        criterionErrors.title = t(
          'criteriaSidepanel.criterionInputErrors.title',
        );
        hasErrors = true;
      }

      if (!criterion.question?.trim()) {
        criterionErrors.question = t(
          'criteriaSidepanel.criterionInputErrors.question',
        );
        hasErrors = true;
      }

      // TODO: uncomment this once we have migrated examples
      // if (!criterion.examples?.trim()) {
      //   criterionErrors.examples = 'Les exemples sont requis';
      //   hasErrors = true;
      // }

      if (Object.keys(criterionErrors).length > 0) {
        errors[criterion.id] = criterionErrors;
      }
    });

    setValidationErrors(errors);
    return hasErrors;
  };

  const clearValidationErrorForCriterion = (criterion: SummaryCriterion) => {
    setValidationErrors(prev => {
      const criterionErrors = prev[criterion.id] || {};

      const updatedErrors = {
        ...criterionErrors,
        title: criterion.title ? undefined : criterionErrors.title,
        question: criterion.question ? undefined : criterionErrors.question,
        examples: criterion.examples ? undefined : criterionErrors.examples,
      };

      // If no errors remain, remove the entire criterion entry
      if (!Object.values(updatedErrors).some(error => error !== undefined)) {
        const { [criterion.id]: _, ...rest } = prev;
        return rest;
      }

      return { ...prev, [criterion.id]: updatedErrors };
    });
  };

  return {
    validationErrors,
    hasErrors,
    validateCriteria,
    clearValidationErrorForCriterion,
  };
}
