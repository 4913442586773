import { Suspense, useEffect } from 'react';

import { AppShell } from '@mantine/core';
import { modals } from '@mantine/modals';

import * as Sentry from '@sentry/react';
import * as reactRouterDom from 'react-router-dom';
import {
  IconArrowsShuffle,
  IconBallpen,
  IconLayoutKanban,
} from '@tabler/icons-react';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { PostHogProvider, usePostHog } from 'posthog-js/react';
import { Outlet, useLocation } from 'react-router-dom';
import { SuperTokensWrapper } from 'supertokens-auth-react';
import { useSessionContext } from 'supertokens-auth-react/recipe/session';

import { Error } from '../shared/components/UI/Error/Error.tsx';
import { Loader } from '../shared/components/UI/Loader/Loader.tsx';
import { Navbar } from '../shared/components/UI/Navbar/Navbar.tsx';

import * as superTokens from '../shared/infra/supertokens.ts';
import { setOnboardingFlag } from '../shared/api/magellan/users/index.ts';
import { ThemeProvider } from '../shared/contexts/MantineTheme.provider.tsx';
import ModalsProvider from '../shared/contexts/Modals.provider.tsx';
import { SidepanelProvider } from '../shared/contexts/Sidepanel.provider.tsx';
import { UrlHashProvider } from '../shared/contexts/UrlHash.provider.tsx';
import { FeatureFlag } from '../shared/entities/FeatureFlags.ts';
import { useActiveUserQuery } from '../shared/hooks/useActiveUserQuery.hook';
import { posthogClient } from '../shared/infra/posthogClient.ts';
import { queryClient } from '../shared/infra/queryClient.ts';
import { Router } from './Router.tsx';

const { BrowserRouter } = reactRouterDom;

superTokens.init();

export default function App() {
  return (
    <ThemeProvider>
      <SuperTokensWrapper>
        <QueryClientProvider client={queryClient}>
          <PostHogProvider client={posthogClient}>
            <ModalsProvider>
              <BrowserRouter>
                <UrlHashProvider>
                  <SidepanelProvider>
                    <Router />
                  </SidepanelProvider>
                </UrlHashProvider>
              </BrowserRouter>
            </ModalsProvider>
          </PostHogProvider>
          <ReactQueryDevtools initialIsOpen={false} buttonPosition="top-left" />
        </QueryClientProvider>
      </SuperTokensWrapper>
    </ThemeProvider>
  );
}

export function AppWithLogin() {
  const session = useSessionContext();
  const activeUserQuery = useActiveUserQuery();
  const location = useLocation();
  const posthog = usePostHog();

  const searchParams = new URLSearchParams(location.search);
  const onboarding = searchParams.get('onboarding');

  // Track user and company information in posthog
  useEffect(() => {
    if (!session.loading && session.doesSessionExist) {
      if (import.meta.env.PROD) {
        const supertokensUserId = session.userId;
        const email = session.accessTokenPayload.email;
        const firstName = session.accessTokenPayload.firstName;
        const lastName = session.accessTokenPayload.lastName;
        const companyUniqueName = session.accessTokenPayload.companyUniqueName;
        const companyDisplayName =
          session.accessTokenPayload.companyDisplayName;

        posthog.identify(supertokensUserId, {
          email,
          ignoreInReporting: activeUserQuery.data.ignoreInReporting,
          firstName,
          lastName,
        });
        posthog.group('company', companyUniqueName, {
          companyUniqueName,
          companyDisplayName,
        });
      }
    }
  }, [activeUserQuery.data.ignoreInReporting, session, posthog]);

  // Track pageviews in posthog
  useEffect(() => {
    posthog.capture('$pageview', {
      $current_url: window.location.href,
    });
  }, [location, posthog]);

  useEffect(() => {
    if (onboarding === 'true') {
      openOnboardingModal();
    }
  }, [onboarding]);

  const openOnboardingModal = () => {
    modals.openContextModal({
      fullScreen: true,
      withCloseButton: true,
      onClose: () => {
        setOnboardingFlag(true);
      },
      modal: 'onboarding',
      transitionProps: {
        transition: 'slide-up',
        duration: 300,
        timingFunction: 'linear',
      },
      innerProps: { step: 0 },
    });
  };

  return (
    <AppShell
      padding={0}
      header={
        <Navbar
          links={[
            {
              link: `/flux`,
              label: "Flux d'opportunités",
              icon: <IconArrowsShuffle size={16} />,
            },
            {
              link: '/pipeline',
              label: 'Pipeline',
              icon: <IconLayoutKanban size={16} />,
            },
            {
              link: '/reponse',
              label: 'Aide à la réponse',
              icon: <IconBallpen size={16} />,
              featureFlag: FeatureFlag.BUYER_SIMULATION,
            },
          ]}
          user={activeUserQuery.data.user}
        />
      }
    >
      <Sentry.ErrorBoundary fallback={<Error />}>
        <Suspense fallback={<Loader title="Chargement" />}>
          <Outlet />
        </Suspense>
      </Sentry.ErrorBoundary>
    </AppShell>
  );
}
