import { Group } from '@mantine/core';

import { IconDeviceFloppy, IconSparkles } from '@tabler/icons-react';
import { useIsMutating } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { Button } from '../../../../../../shared/components/UI/Button/Button';

import { replaceAllSummaryCriteria } from '../../../../../../shared/api/magellan/summarySheet';
import type { SummaryCriterion } from '../../../../../../shared/entities/SummaryCriterion';
import { useCanGenerateSummaryCriterionAnswers } from '../../hooks/useCanGenerate.hook';
import { useGetSummaryCriterionAnswers } from '../../hooks/useGetSummaryCriterionAnswers.hook';
import { useReplaceAllCriteriaMutation } from '../../hooks/useReplaceAllCriteriaMutation.hook';
import { useSummarySheetTrigger } from '../../hooks/useSummarySheetTrigger.hook';
import { LoaderNotif, LoadingState } from '../LoaderNotif';

type FooterProps = {
  validateCriteria: () => boolean;
  criteria: SummaryCriterion[];
  hasChanges: boolean;
  hasErrors: boolean;
  tenderId: number;
  closePanel: () => void;
};
export function Footer({
  validateCriteria,
  criteria,
  hasChanges,
  hasErrors,
  tenderId,
  closePanel,
}: FooterProps) {
  const { t } = useTranslation(['summarySheet']);

  const canGenerateSummarySheetQuery =
    useCanGenerateSummaryCriterionAnswers(tenderId);
  const { triggerSummarySheetGeneration } = useSummarySheetTrigger();
  const replaceAllCriteriaMutation = useReplaceAllCriteriaMutation();
  const SummarySheetCriterionAnswersQuery =
    useGetSummaryCriterionAnswers(tenderId);
  const numberOfUpdates = useIsMutating({
    mutationKey: [replaceAllSummaryCriteria.name],
  });
  const isLoading = numberOfUpdates > 0;

  const hasPreviousGeneration =
    SummarySheetCriterionAnswersQuery.data?.criteriaAnswers.length > 0;
  const isEmpty = criteria.length === 0;

  const handleSaveCriteria = () => {
    const hasErrors = validateCriteria();

    if (!hasErrors) {
      replaceAllCriteriaMutation.mutate(criteria);
    }
  };

  const handleTriggerAnalysis = () => {
    const hasErrors = validateCriteria();

    if (!hasErrors && canGenerateSummarySheetQuery.data.isGeneratable) {
      triggerSummarySheetGeneration({ tenderId, criteria });
      closePanel();
    }
  };

  return (
    <Group
      p="03"
      spacing="02"
      position="right"
      bg="white"
      w="100%"
      sx={{
        zIndex: 10,
        boxShadow: `0px 1px 2px 0px rgba(0, 0, 0, 0.30), 0px 2px 6px 2px rgba(0, 0, 0, 0.15);`,
      }}
    >
      <LoaderNotif
        loadingState={isLoading ? LoadingState.LOADING : LoadingState.WAITING}
      />
      <Button
        variant="default"
        size="sm"
        leftIcon={<IconDeviceFloppy />}
        onClick={handleSaveCriteria}
        disabled={!hasChanges || hasErrors || isLoading}
      >
        {t('criteriaSidepanel.footerCta.saveButton')}
      </Button>
      <Button
        variant="filled"
        size="sm"
        disabled={hasErrors || isEmpty}
        leftIcon={<IconSparkles />}
        onClick={handleTriggerAnalysis}
      >
        {hasPreviousGeneration
          ? t('criteriaSidepanel.footerCta.reGenerateButton')
          : t('criteriaSidepanel.footerCta.generateButton')}
      </Button>
    </Group>
  );
}
