import type { ReactElement } from 'react';
import { useState } from 'react';

import { Stack } from '@mantine/core';

import type { Thread } from '../../../../../shared/entities/Thread.ts';
import { CommentComponent } from './CommentComponent.tsx';
import { CommentUnfoldButton } from './CommentUnfoldButton.tsx';
import { NewCommentInput } from './NewCommentInput.tsx';
import { SummaryCriterionAnswerPreviewCard } from './SummarySheetPreviewCard.tsx';

type ThreadComponentProps = {
  thread: Thread;
  tenderId: number;
  showBorderOnFocus?: boolean;
  px?: string;
};

export function ThreadComponent({
  thread,
  tenderId,
  showBorderOnFocus,
  px = '04',
}: ThreadComponentProps) {
  return (
    <Stack
      spacing="01"
      px={px}
      h="fit-content"
      sx={{ transition: 'heigth 1s' }}
    >
      <ThreadContent thread={thread} tenderId={tenderId} />
      <NewCommentInput
        threadId={thread.id}
        showBorderOnFocus={showBorderOnFocus}
        tenderId={tenderId}
      />
    </Stack>
  );
}

type ThreadContentProps = {
  thread: Thread;
  tenderId?: number;
};

function ThreadContent({ thread, tenderId }: ThreadContentProps) {
  const { comments } = thread;
  const [isFolded, setIsFolded] = useState(comments.length > 3 ? true : false);

  const summaryCriterionAnswerPreviewCard = thread.summaryCriterionAnswer && (
    <SummaryCriterionAnswerPreviewCard
      question={thread.summaryCriterionAnswer.originalQuestion}
      criterionAnswerId={thread.summaryCriterionAnswer.id}
    />
  );

  const unfoldedComments = comments.map((comment, index) => (
    <CommentComponent
      key={comment.id}
      comment={comment}
      tenderId={tenderId}
      PreviewCard={index === 0 ? summaryCriterionAnswerPreviewCard : undefined}
    />
  ));

  const foldedComments: ReactElement[] = [
    <CommentComponent
      key={comments[0].id}
      comment={thread.comments[0]}
      tenderId={tenderId}
      PreviewCard={summaryCriterionAnswerPreviewCard}
    />,
    <CommentUnfoldButton
      key={`unfold-${thread.id}`}
      number={comments.length - 2}
      unfold={() => setIsFolded(false)}
    />,
    <CommentComponent
      key={comments[comments.length - 1].id}
      comment={thread.comments[comments.length - 1]}
      tenderId={tenderId}
    />,
  ].map(element => element);

  if (isFolded) {
    return foldedComments;
  }

  return unfoldedComments;
}
