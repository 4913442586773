import type { UseSuspenseQueryResult } from '@tanstack/react-query';
import { useSuspenseQuery } from '@tanstack/react-query';

import { getSummaryCriterionAnswers } from '../../../../../shared/api/magellan/summarySheet';
import type { GetSummaryCriteriaAnswersResponseDTO } from '../../../../../shared/api/magellan/summarySheet/dto';

export function useGetSummaryCriterionAnswers(
  tenderId: number,
): UseSuspenseQueryResult<GetSummaryCriteriaAnswersResponseDTO> {
  const queryKey = [getSummaryCriterionAnswers.name, tenderId];
  return useSuspenseQuery({
    queryKey,
    queryFn: () => getSummaryCriterionAnswers(tenderId),
    refetchInterval: query => {
      const hasRunningOTFQuestion = query.state.data?.criteriaAnswers.filter(
        criterion => criterion.ragStatus === 'RUNNING',
      ).length;
      if (
        query.state.data?.summarySheetRAGStatus === 'RUNNING' ||
        hasRunningOTFQuestion
      ) {
        return 5000;
      }

      return false;
    },
  });
}
