import type { CreateSummaryCriterionRequestDTO } from '../../../../../shared/api/magellan/summarySheet/dto';
import type { SummaryCriterion } from '../../../../../shared/entities/SummaryCriterion';

export function sanitizeCriteria(
  criteria: SummaryCriterion[],
): CreateSummaryCriterionRequestDTO[] {
  return criteria.map(criterion => {
    const { id: _id, ...criterionWithoutId } = criterion;

    return {
      ...criterionWithoutId,
    };
  });
}
