import { Group, Text } from '@mantine/core';

import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import { Avatar } from '../../../../../../shared/components/UI/Avatar/Avatar';

import type { Thread } from '../../../../../../shared/entities/Thread';
import type { User } from '../../../../../../shared/entities/User';

type EditedAtRowProps = {
  user: User;
  date: Date;
  thread?: Thread | null;
  criterionAnswerId: number;
  tenderId: number;
};

export function EditedAtRow({ user, date }: EditedAtRowProps) {
  const { t } = useTranslation(['summarySheet']);
  const formatedDate = dayjs(date).fromNow();
  return (
    <Group noWrap spacing="02" pl="05" mb="05" align="flex-start">
      <Avatar size="md" color={user.avatarColor}>
        {user.firstName.charAt(0)}
        {user.lastName.charAt(0)}
      </Avatar>
      <Text variant="xs" fw="300" c="gray.5">
        {t('answerDetailPanel.editedAt', {
          user: `${user.firstName} ${user.lastName}`,
          date: formatedDate,
        })}
      </Text>
    </Group>
  );
}
