export type FeatureFlagsType = {
  [key in FeatureFlag]: boolean;
};

export enum FeatureFlag {
  DISPLAY_STREAM_WITH_TAKEN_DECISIONS = 'DISPLAY_STREAM_WITH_TAKEN_DECISIONS',
  /**
   * @knipignore
   * This feature flag is by nature temporary
   * So we can safely ignore it in knip
   */
  PRE_RELEASE = 'PRE_RELEASE',
  BUYER_SIMULATION = 'BUYER_SIMULATION',
}
