import { Group, Stack, Text, ThemeIcon } from '@mantine/core';

import { IconMessageExclamation } from '@tabler/icons-react';
import Markdown from 'react-markdown';

import type { requirementEvaluation } from '../../../../../shared/entities/BidResponse';
import { MISSING_EVALUATION } from '../SimulationTab';
import { CellLoader } from './IACellLoader';
import { SourcesContent } from './SourcesContent';

type RequirementEvaluationCellProps = {
  requirementEvaluation: requirementEvaluation;
};
export const RequirementEvaluationCell = ({
  requirementEvaluation,
}: RequirementEvaluationCellProps) => {
  if (!requirementEvaluation.retrievedAnswerFromProposal) {
    return <CellLoader />;
  }

  return (
    <Stack>
      <Group spacing="01">
        {requirementEvaluation.retrievedAnswerFromProposal ===
          MISSING_EVALUATION && (
          <ThemeIcon c="red.5" bg="transparent" size="sm">
            <IconMessageExclamation />
          </ThemeIcon>
        )}
        <Text variant="sm" fw={400} c="gray.9">
          <Markdown>
            {requirementEvaluation.retrievedAnswerFromProposal}
          </Markdown>
        </Text>
      </Group>
      {requirementEvaluation.sources &&
        requirementEvaluation.retrievedAnswerFromProposal !==
          MISSING_EVALUATION && (
          <SourcesContent sources={requirementEvaluation.sources} />
        )}
    </Stack>
  );
};
