import { Text, createStyles } from '@mantine/core';

import { useTranslation } from 'react-i18next';

const useStyles = createStyles(theme => ({
  wrapper: {
    padding: `${theme.spacing['02']} ${theme.spacing['03']}`,
    borderRadius: theme.radius.lg,
    display: 'flex',
    marginRight: 'auto',
    alignItems: 'center',
    gap: theme.spacing['02'],
    backgroundColor: theme.colors.primary[1],
  },
  text: {
    color: theme.colors.gray[6],
  },
  gotIt: {
    fontWeight: 600,
    textDecoration: 'underline',
    cursor: 'pointer',
  },
}));

export function InfoMessage({
  onGotItClick,
  isVisible,
}: {
  onGotItClick: () => void;
  isVisible: boolean;
}) {
  const { classes, cx } = useStyles();
  const { t } = useTranslation(['summarySheet', 'common']);

  if (!isVisible) {
    return null;
  }

  return (
    <div className={classes.wrapper}>
      <span>⚠️</span>
      <Text className={classes.text} variant="xs">
        {t('summarySheet:unreadableFilesMessage')}
      </Text>
      <Text
        className={cx(classes.text, classes.gotIt)}
        variant="xs"
        onClick={onGotItClick}
      >
        {t('common:gotIt')}
      </Text>
    </div>
  );
}
