import { Group, Stack } from '@mantine/core';

import {
  IconCalendarDue,
  IconCircleDashed,
  IconUserCircle,
} from '@tabler/icons-react';
import { useSuspenseQuery } from '@tanstack/react-query';

import type { MultiCheckboxOption } from '../../../../../shared/components/UI/MultiCheckbox/MultiCheckbox';
import TenderStatusBadge from '../../../../../shared/components/UI/TenderStatusBadge/TenderStatusBadge';
import { UserWithAvatar } from '../../../../../shared/components/UI/UserWithAvatar/UserWithAvatar';

import type { TableFilter } from '../../../../../app/pages/Pipeline.page';
import { getUsers } from '../../../../../shared/api/magellan/users';
import { DecisionStatus } from '../../../../../shared/entities/Interaction';
import { TenderStatus } from '../../../../../shared/entities/Tender';
import type { User } from '../../../../../shared/entities/User';
import { useActiveUserQuery } from '../../../../../shared/hooks/useActiveUserQuery.hook';
import type { DecisionStatusTab } from '../../../hooks/useTabs.hook';
import type { TableColumnWithFilter } from '../utils/columnDefinitions';
import { TableColumn } from '../utils/columnDefinitions';
import { FilterInputs } from './FilterInputs';
import { FilterTags } from './FilterTags';
import { GlobalSearchInput } from './GlobalSearchInput';

type DecisionListFiltersProps = {
  rowCount: number | undefined;
  filters: TableFilter[];
  onFilterChange: (columnId: TableColumnWithFilter, value: string[]) => void;
  onGlobalFilterChange: (value?: string) => void;
  handleRemoveFilter: (columnId: TableColumnWithFilter) => void;
  resetFilters: () => void;
  isRenewal?: boolean;
};

export function PipelineFilters({
  rowCount,
  filters,
  onFilterChange,
  onGlobalFilterChange,
  handleRemoveFilter,
  resetFilters,
  isRenewal = false,
}: DecisionListFiltersProps) {
  const activeUserQuery = useActiveUserQuery();
  const { data: users } = useSuspenseQuery({
    queryKey: [getUsers.name],
    queryFn: getUsers,
  });

  const { user: activeUser } = activeUserQuery.data;

  // order the user array to place the active user in first place
  const orderedUsers = users?.sort((a, b) => {
    if (a.email === activeUser.email) return -1;
    if (b.email === activeUser.email) return 1;
    return 0;
  });

  const filterConfig = getFilterConfig(orderedUsers);

  return (
    <Stack mx="06" pt="03" spacing="03">
      <Group spacing={0} position="apart">
        <FilterInputs
          {...{
            rowCount,
            filters,
            filterConfig,
            onFilterChange,
            resetFilters,
            isRenewal,
          }}
        />
        <GlobalSearchInput updateGlobalFilter={onGlobalFilterChange} />
      </Group>
      <FilterTags
        filters={filters}
        handleRemoveFilter={handleRemoveFilter}
        filterConfig={filterConfig}
      />
    </Stack>
  );
}

export type FilterConfig = {
  displayFilterOnlyFor?: DecisionStatusTab[];
  filterLabel: string;
  columnId: TableColumn;
  options: (MultiCheckboxOption & { valueLabel: string })[];
  icon: React.ReactElement;
};

const getFilterConfig = (
  users: User[],
): Record<TableColumnWithFilter, FilterConfig> => ({
  [TableColumn.STATUS]: {
    filterLabel: 'Status',
    columnId: TableColumn.STATUS,
    icon: <IconCircleDashed size={16} />,
    options: [
      {
        value: TenderStatus.CLOSED,
        label: <TenderStatusBadge status={TenderStatus.CLOSED} />,
        valueLabel: 'Fermé',
      },
      {
        value: TenderStatus.OPEN,
        label: <TenderStatusBadge status={TenderStatus.OPEN} />,
        valueLabel: 'Ouvert',
      },
    ],
  },
  [TableColumn.OWNER]: {
    filterLabel: 'Responsable',
    columnId: TableColumn.OWNER,
    icon: <IconUserCircle size={16} />,
    options: users.map(user => ({
      value: user.id,
      label: (
        <UserWithAvatar
          avatarColor={user.avatarColor}
          firstName={user.firstName}
          lastName={user.lastName}
          isDisabled={!!user.disabledAt}
        />
      ),
      valueLabel: `${user.firstName} ${user.lastName}`,
    })),
  },
  [TableColumn.RESPONSE_DEADLINE]: {
    displayFilterOnlyFor: [DecisionStatus.TO_ANALYZE, DecisionStatus.GO],
    filterLabel: 'À déposer avant le',
    columnId: TableColumn.RESPONSE_DEADLINE,
    icon: <IconCalendarDue size={16} />,
    options: [],
  },
});
