import { Group, Stack, Text } from '@mantine/core';

import type { DataPointSource } from '../../entities/DataPointMeta';
import { useSourceSidePanel } from '../../hooks/useSourceSidePanel';
import { SourceButton } from './SourceButton';

export function SourcesSection({
  sources,
  tenderId,
}: {
  sources: DataPointSource[];
  tenderId: number;
}) {
  const { openSourcePanel, selectedSource } = useSourceSidePanel({
    tenderId,
    sources,
  });

  return (
    <Stack spacing="02">
      <Text variant="xs" fw={500} c="gray.4">
        Sources
      </Text>
      <Group spacing="02">
        {sources.map((source, index) => (
          <SourceButton
            key={source.docFilePath + index}
            onClick={() => {
              openSourcePanel(source);
            }}
            label={formatSourceLabel(source)}
            isActive={
              source.originalChunkExtract ===
              selectedSource?.originalChunkExtract
            }
          />
        ))}
      </Group>
    </Stack>
  );
}

export function formatSourceLabel(source: DataPointSource) {
  return source.pageNumber
    ? `${source.docFilePath} - Page ${source.pageNumber}`
    : source.docFilePath;
}
