import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import frBuyer from '../locales/fr/buyer.json';
import frCollaboration from '../locales/fr/collaboration.json';
import frCommon from '../locales/fr/common.json';
import frNotice from '../locales/fr/notice.json';
import frShared from '../locales/fr/shared.json';
import frSummarySheet from '../locales/fr/summarySheet.json';

const resources = {
  fr: {
    common: frCommon,
    buyer: frBuyer,
    summarySheet: frSummarySheet,
    notice: frNotice,
    shared: frShared,
    collaboration: frCollaboration,
  },
};

export function initI18next() {
  i18n.use(initReactI18next).init({
    resources,
    ns: [
      'common',
      'buyer',
      'summarySheet',
      'notice',
      'shared',
      'collaboration',
    ],
    fallbackLng: 'fr',
    lng: 'fr',
    debug: process.env.NODE_ENV !== 'production',

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });
}
