import { createContext, useContext } from 'react';

import type { FindOneTenderResponseDTO } from '../../../shared/api/magellan/tender/dto';

type NoticeContextType = {
  tender: FindOneTenderResponseDTO;
};

const NoticeContext = createContext<NoticeContextType | undefined>(undefined);

type NoticeProviderProps = {
  tender: FindOneTenderResponseDTO;
  children: React.ReactNode;
};

export function NoticeProvider({ tender, children }: NoticeProviderProps) {
  return (
    <NoticeContext.Provider value={{ tender }}>
      {children}
    </NoticeContext.Provider>
  );
}

export const useNoticeContext = () => {
  const context = useContext(NoticeContext);
  if (!context) {
    throw new Error('useNoticeContext must be used within a NoticeProvider');
  }
  return context;
};
