import { Group, Stack, Text } from '@mantine/core';

import Markdown from 'react-markdown';
import { useParams } from 'react-router-dom';

import { SourcesSection } from '../../../../shared/components/SourcesSection/SourcesSection';
import type { DataPointSource } from '../../../../shared/entities/DataPointMeta';
import type { AiRelevanceFeedbackStatus } from '../../../../shared/entities/InstantAnalysisAnswer';
import { AiRelevanceFeedback } from '../AiRelevanceFeedback';
import { AnswerLeftBorder } from './AnswerLeftBorder';

type LlmAnswerProps = {
  answer: string;
  feedback: AiRelevanceFeedbackStatus | null;
  handleAiRelevanceFeedback: (feedback: AiRelevanceFeedbackStatus) => void;
  sources: DataPointSource[];
};

export const LlmAnswer = ({
  answer,
  feedback,
  handleAiRelevanceFeedback,
  sources,
}: LlmAnswerProps) => {
  const { id: tenderId } = useParams();
  return (
    <Stack spacing="md">
      <Stack spacing="02">
        <Text variant="xs" fw={500} c="gray.6">
          Réponse
        </Text>
        <Group spacing="04" noWrap h="100%" sx={{ alignItems: 'stretch' }}>
          <AnswerLeftBorder />
          <Stack spacing="02">
            <Text variant="md" fw={400} c="gray.8">
              <Markdown>{answer}</Markdown>
            </Text>
            <AiRelevanceFeedback
              feedback={feedback}
              handleFeedback={handleAiRelevanceFeedback}
            />
          </Stack>
        </Group>
      </Stack>
      <SourcesSection sources={sources} tenderId={Number(tenderId)} />
    </Stack>
  );
};
