import { useMutation, useQueryClient } from '@tanstack/react-query';

import {
  generateSummarySheetWithOnTheFlyQuestion,
  getSummaryCriterionAnswers,
} from '../../../../../shared/api/magellan/summarySheet';

type OnTheFlyQuestionMutationProps = {
  tenderId: number;
  question: string;
};

export function useOnTheFlyQuestionMutation() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ tenderId, question }: OnTheFlyQuestionMutationProps) =>
      generateSummarySheetWithOnTheFlyQuestion(tenderId, question),
    onSuccess: (_data, { tenderId }) => {
      queryClient.invalidateQueries({
        queryKey: [getSummaryCriterionAnswers.name, tenderId],
      });
    },
  });
}
