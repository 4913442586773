import { Box, Group, Image, Paper, Stack, Text, Title } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';

import {
  IconCheck,
  IconEdit,
  IconExclamationCircle,
  IconExternalLink,
  IconFileZip,
  IconPlayerPlayFilled,
} from '@tabler/icons-react';
import { t } from 'i18next';
import { Trans, useTranslation } from 'react-i18next';

import { Button } from '../../../../../shared/components/UI/Button/Button';
import TenderStatusBadge from '../../../../../shared/components/UI/TenderStatusBadge/TenderStatusBadge';

import type { NotGeneratableReason } from '../../../../../shared/api/magellan/summarySheet/dto';
import { useSidepanel } from '../../../../../shared/contexts/Sidepanel.provider';
import { DceRequestStatus } from '../../../../../shared/entities/DceRequestStatus';
import { TenderStatus } from '../../../../../shared/entities/Tender';
import InstantAnalysisImg from '../../../assets/instant-analysis-preview.svg';
import { useNoticeContext } from '../../../contexts/Notice.provider';
import { useSendDCERequest } from '../../../hooks/useSendDCERequest';
import { useCanGenerateSummaryCriterionAnswers } from '../hooks/useCanGenerate.hook';
import { useGetCriteriaQuery } from '../hooks/useGetCriteriaQuery.hook';
import { useSummarySheetTrigger } from '../hooks/useSummarySheetTrigger.hook';
import { CriteriaSidepanelContent } from './summarySheetPanel/CriteriaSidepanelContent';

// TODO: rename the guide
const userGuideUrl =
  'https://tengo-cc.notion.site/Guide-d-utilisation-de-l-Analyse-Instantan-e-83d4952a49b04f5e8e89bb87f03d631e';

export function TriggerCard() {
  const isSmallScreen = useMediaQuery('(max-width: 1440px)');
  const { tender } = useNoticeContext();
  const canGenerateSummarySheetQuery = useCanGenerateSummaryCriterionAnswers(
    tender.id,
  );

  return (
    <Stack spacing="05">
      {canGenerateSummarySheetQuery.data?.error && (
        <WarningCard error={canGenerateSummarySheetQuery.data.error} />
      )}
      <Box
        p="05"
        mb="05"
        sx={theme => ({
          border: `1px solid ${theme.colors.gray[1]}`,
          borderRadius: theme.radius.md,
          boxShadow: theme.shadows.xs,
        })}
      >
        <Group
          p="06"
          pr="10"
          spacing="10"
          noWrap={!isSmallScreen}
          mx={isSmallScreen ? 'auto' : 0}
          sx={theme => ({
            borderRadius: theme.radius.md,
            background: theme.other.gradients.backgroundMedium,
            justifyContent: isSmallScreen ? 'center' : 'start',
          })}
        >
          <Image
            src={InstantAnalysisImg}
            width={540}
            height={230}
            radius="lg"
          />
          <Content />
        </Group>
      </Box>
    </Stack>
  );
}

const Content = () => {
  const { t } = useTranslation('summarySheet');
  const { tender } = useNoticeContext();
  const canGenerateSummarySheetQuery = useCanGenerateSummaryCriterionAnswers(
    tender.id,
  );

  const isGeneratable =
    canGenerateSummarySheetQuery.data.isGeneratable &&
    canGenerateSummarySheetQuery.data.error !== 'NO_SUPPORTED_DOCUMENTS';

  return (
    <Stack spacing="04">
      <Stack spacing="02" maw="600px">
        <Title order={4}>{t('triggerCard.title')}</Title>
        <Text variant="sm" fw={400} c="gray.6">
          {t('triggerCard.subtitle')}
        </Text>
      </Stack>
      {isGeneratable ? <TriggerAnalysisButtonGroup /> : <RequestDocuments />}
    </Stack>
  );
};

const RequestDocuments = () => {
  const { tender } = useNoticeContext();

  if (tender.dceRequestStatus === DceRequestStatus.IN_SEARCH) {
    return <SearchingCard />;
  }

  if (tender.status === TenderStatus.CLOSED) {
    return <ClosedTenderCard />;
  }

  return <RequestCard />;
};

const SearchingCard = () => {
  const { t } = useTranslation('summarySheet');

  return (
    <Stack spacing="xl">
      <Text
        variant="sm"
        fw="500"
        color="dark.9"
        sx={{ whiteSpace: 'pre-wrap' }}
      >
        {t('triggerCard.searchingCard.subtitle')}
      </Text>
      <Button
        disabled
        variant="filled"
        maw="250px"
        radius="md"
        rightIcon={<IconCheck size={16} />}
      >
        {t('triggerCard.searchingCard.cta')}
      </Button>
    </Stack>
  );
};

const ClosedTenderCard = () => {
  return (
    <Text variant="sm" fw={400} c="gray.4">
      <Trans
        ns="summarySheet"
        i18nKey="triggerCard.closedTenderCard.subtitle"
        components={{
          badge: <TenderStatusBadge status={TenderStatus.CLOSED} />,
        }}
      />
    </Text>
  );
};

const RequestCard = () => {
  const { t } = useTranslation('summarySheet');
  const { tender } = useNoticeContext();
  const { sendDCERequest } = useSendDCERequest();

  return (
    <Stack spacing="04">
      <Group noWrap spacing="04" c="gray.6">
        <IconExclamationCircle size={32} stroke={1} />
        <Stack spacing={0}>
          <Text variant="sm" fw="500" c="gray.9">
            {t('triggerCard.requestCard.title')}
          </Text>
          <Text variant="sm" fw="400" c="gray.9">
            {t('triggerCard.requestCard.subtitle')}
          </Text>
        </Stack>
      </Group>
      <Group spacing="02">
        <Button
          disabled={tender.dceRequestStatus === DceRequestStatus.IN_SEARCH}
          variant="filled"
          maw="250px"
          leftIcon={<IconFileZip />}
          onClick={() => sendDCERequest({ tenderId: tender.id })}
        >
          {t('triggerCard.requestCard.cta')}
        </Button>
        <Button
          variant="subtle"
          rightIcon={<IconExternalLink stroke={1.5} />}
          component="a"
          href={userGuideUrl}
          target="_blank"
        >
          {t('triggerCard.userGuideCta')}
        </Button>
      </Group>
    </Stack>
  );
};

const TriggerAnalysisButtonGroup = () => {
  const { t } = useTranslation('summarySheet');
  const { tender } = useNoticeContext();
  const CanGenerateSummarySheetQuery = useCanGenerateSummaryCriterionAnswers(
    tender.id,
  );

  const criteriaQuery = useGetCriteriaQuery();
  const { triggerSummarySheetGeneration } = useSummarySheetTrigger();
  const { togglePanel, closePanel } = useSidepanel();
  const criteria = criteriaQuery.data?.criteria || [];

  const openSidepanel = () => {
    togglePanel({
      header: t('title'),
      content: (
        <CriteriaSidepanelContent
          tenderId={tender.id}
          closePanel={closePanel}
        />
      ),
    });
  };

  if (criteria.length === 0) {
    return (
      <Group spacing="02">
        <Button
          leftIcon={<IconPlayerPlayFilled size={16} />}
          disabled={!CanGenerateSummarySheetQuery.data.isGeneratable}
          onClick={openSidepanel}
        >
          {t('triggerCard.triggerAnalysisButtonGroup.create')}
        </Button>
        <Button
          variant="subtle"
          rightIcon={<IconExternalLink stroke={1.5} />}
          component="a"
          href={userGuideUrl}
          target="_blank"
        >
          {t('triggerCard.userGuideCta')}
        </Button>
      </Group>
    );
  }

  return (
    <Group spacing="02">
      <Button
        leftIcon={<IconPlayerPlayFilled size={16} />}
        disabled={!CanGenerateSummarySheetQuery.data.isGeneratable}
        onClick={() => triggerSummarySheetGeneration({ tenderId: tender.id })}
      >
        {t('triggerCard.triggerAnalysisButtonGroup.title')}
      </Button>
      <Button
        variant="outline"
        leftIcon={<IconEdit size={16} stroke={1.5} />}
        onClick={openSidepanel}
      >
        {t('triggerCard.editCta')}
      </Button>
    </Group>
  );
};

type WarningCardProps = {
  error: NotGeneratableReason;
};

const WarningCard = ({ error }: WarningCardProps) => {
  const { title, message } = getWarningTexts(error);

  return (
    <Paper p="16px 12px" radius={8} bg="red.0" shadow="md">
      <Group noWrap align="flex-start" c="red.9" spacing="md">
        <IconExclamationCircle
          size={16}
          style={{ marginTop: '2px', marginLeft: '2px' }}
        />
        <Stack spacing="xxs">
          <Text variant="sm" fw="700">
            {title}
          </Text>
          <Text variant="sm" fw="400" color="dark.9">
            {message}
          </Text>
        </Stack>
      </Group>
    </Paper>
  );
};

type WarningTexts = {
  title: string;
  message: string;
};

const getWarningTexts = (error: NotGeneratableReason): WarningTexts => {
  const config: Record<NotGeneratableReason, WarningTexts> = {
    NO_DOCUMENTS: {
      title: t('summarySheet:triggerCard.warningCard.noDocuments.title'),
      message: t('summarySheet:triggerCard.warningCard.noDocuments.message'),
    },
    NO_SUPPORTED_DOCUMENTS: {
      title: t(
        'summarySheet:triggerCard.warningCard.noSupportedDocuments.title',
      ),
      message: t(
        'summarySheet:triggerCard.warningCard.noSupportedDocuments.message',
      ),
    },
  };

  return config[error];
};
