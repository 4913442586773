import * as Sentry from '@sentry/react';

import { displayErrorNotification } from '../../../../../shared/components/notifications/errorNotification';

export const handleError = (error: Error, message: string) => {
  Sentry.captureException(error);
  displayErrorNotification('Erreur', message);

  throw Error(`Error on threads: ${message}`);
};
