import { Image as MantineImage } from '@mantine/core';

export function Image({ fileUrl }: { fileUrl: string }) {
  return (
    <MantineImage
      src={fileUrl}
      alt="Document preview"
      fit="contain"
      height="100%"
    />
  );
}
