import { useMantineTheme } from '@mantine/core';

import { useTranslation } from 'react-i18next';

export function Amount() {
  const { t } = useTranslation('shared');

  const bulletPoints = t('renderLlmData.tooltip.amount', {
    returnObjects: true,
  });

  return <KeyInfoTooltip bulletPoints={bulletPoints} />;
}

export function Duration() {
  const { t } = useTranslation('shared');

  const bulletPoints = t('renderLlmData.tooltip.duration', {
    returnObjects: true,
  });

  return <KeyInfoTooltip bulletPoints={bulletPoints} />;
}

function KeyInfoTooltip({ bulletPoints }: { bulletPoints: string[] }) {
  const theme = useMantineTheme();

  return (
    <ul
      style={{
        paddingLeft: theme.spacing['02'],
        margin: theme.spacing['02'],
        fontSize: theme.fontSizes.xs,
      }}
    >
      {bulletPoints.map(content => (
        <li key={content}>{content}</li>
      ))}
    </ul>
  );
}
