import type { ComponentPropsWithoutRef } from 'react';
import { forwardRef, useEffect, useRef, useState } from 'react';

import { Box, Group, Loader, Modal, Stack, Text } from '@mantine/core';
import { useDebouncedValue, useDisclosure } from '@mantine/hooks';

import { IconSearch } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';

import { Button } from '../../../shared/components/UI/Button/Button';
import { BuyerLogo } from '../../../shared/components/UI/BuyerLogo/BuyerLogo';
import { Select } from '../../../shared/components/UI/Select/Select';
import { Tooltip } from '../../../shared/components/UI/Tooltip/Tooltip';

import { useUrlHash } from '../../../shared/contexts/UrlHash.provider';
import { useBuyerSearch } from '../../../shared/hooks/useBuyerSearch.hook';
import { concatPostalCode } from '../../../shared/utils/domain';
import { UrlHashBuilder } from '../../../shared/utils/navigation/UrlHashBuilder';

type BuyerSearchBarProps = {
  onBuyersChange?: (length?: number) => void;
  onValueChange?: () => void;
  withoutResultBorder?: boolean;
};

export const BuyerSearchBar = ({
  onBuyersChange,
  onValueChange: closeModal,
  withoutResultBorder = false,
}: BuyerSearchBarProps) => {
  const { redirectTo } = useUrlHash();
  const { t } = useTranslation('buyer');
  const searchInput = useRef<HTMLInputElement>(null);
  const [searchValue, setSearchValue] = useState<string>('');
  const [debouncedSearchValue] = useDebouncedValue(searchValue, 200);
  const { buyers, isLoading } = useBuyerSearch(debouncedSearchValue);

  const handleValueChanges = (value: string) => {
    if (!value) return;

    setSearchValue('');
    closeModal && closeModal();
    redirectTo(
      `/buyer/${value}`,
      new UrlHashBuilder().addLocationData().build(),
    );
  };

  const transformedBuyers: BuyerItemProps[] = buyers.map(buyer => ({
    normalizedname: buyer.normalizedName,
    originalname: buyer.originalName,
    postalCode: buyer.postalCode,
    logoURL: buyer.logoURL,
    tenderCount: buyer.tendersCount,
    value: buyer.id,
    label: buyer.normalizedName,
    id: buyer.id,
  }));

  useEffect(() => {
    onBuyersChange && onBuyersChange(transformedBuyers.length);
  }, [onBuyersChange, searchValue, transformedBuyers.length]);

  const nothingFound =
    isLoading || debouncedSearchValue !== searchValue ? (
      <Loader size="sm" />
    ) : (
      <Stack spacing={0}>
        <Text variant="sm" c="dark.3">
          {t('buyerSearchBar.nothingFound')}
        </Text>
        <Text variant="sm" c="dark.7" fw={700}>
          "{searchValue}"
        </Text>
      </Stack>
    );
  return (
    <Box ref={searchInput}>
      <Select
        miw={320}
        size="xs"
        variant="filled"
        placeholder={t('buyerSearchBar.placeholder')}
        searchable
        value=""
        onChange={handleValueChanges}
        searchValue={searchValue}
        onSearchChange={setSearchValue}
        limit={30}
        nothingFound={
          searchValue.length < 2
            ? t('buyerSearchBar.startTyping')
            : nothingFound
        }
        itemComponent={SelectBuyerItem}
        data={transformedBuyers}
        maxDropdownHeight={360}
        icon={<IconSearch />}
        rightSection={<></>}
        filter={() => true}
        withoutResultBorder={withoutResultBorder}
        shadow={withoutResultBorder ? 'none' : 'md'}
        dropdownWidth={withoutResultBorder ? undefined : '600px'}
      />
    </Box>
  );
};

export const BuyerSearchButton = () => {
  const [opened, { open, close }] = useDisclosure(false);
  const [numberOfBuyers, setNumberOfBuyers] = useState<number | undefined>();
  const [height, setHeight] = useState<number>();
  const buyerItemHeight = 41;
  const searchBarWithPaddingHeight = 38;
  const notFoundScreenHeight = 106;

  useEffect(() => {
    const defaultHeight =
      numberOfBuyers === 0 ? notFoundScreenHeight : undefined;
    const dynamicHeight = numberOfBuyers
      ? numberOfBuyers * buyerItemHeight + searchBarWithPaddingHeight
      : defaultHeight;
    setHeight(dynamicHeight);
  }, [numberOfBuyers]);

  return (
    <>
      {/* Using mantine modal directly instead of our modal provider because the height needs to be dynamic. */}
      <Modal
        opened={opened}
        onClose={() => {
          close();
          setNumberOfBuyers(undefined);
        }}
        w={320}
        withCloseButton={false}
        radius="md"
        yOffset="30%"
      >
        <Stack mih="86px" mah={406} h={height} py="01" w="100%">
          <BuyerSearchBar
            onBuyersChange={setNumberOfBuyers}
            onValueChange={close}
            withoutResultBorder
          />
          <Text pt="3px" variant="sm" fw={400} c="gray.6" align="center">
            Commencez à écrire pour afficher des suggestions
          </Text>
        </Stack>
      </Modal>
      <Button
        variant="light"
        color="gray"
        size="sm"
        style={{}}
        px="02"
        leftIcon={<IconSearch size={16} />}
        onClick={() => {
          open();
        }}
      />
    </>
  );
};

interface BuyerItemProps extends ComponentPropsWithoutRef<'div'> {
  normalizedname: string;
  originalname: string;
  postalCode?: string;
  logoURL?: string;
  tenderCount?: number;
  value: string;
  label: string;
  id: string;
}

const SelectBuyerItem = forwardRef<HTMLDivElement, BuyerItemProps>(
  (props: BuyerItemProps, ref) => {
    const { originalname, postalCode, logoURL, tenderCount, id, ...others } =
      props;
    const truncatedTenderCount =
      tenderCount && tenderCount > 99 ? '+99' : tenderCount;

    return (
      <Box
        ref={ref}
        {...others}
        // overring the default hover effect to avoid some flashing:
        onMouseEnter={undefined}
        onMouseLeave={undefined}
        sx={{
          ':hover': {
            background: `linear-gradient(90deg, #F7F7F8 52%, #FFFFFF 100%)`,
          },
        }}
      >
        <Group noWrap position="apart">
          <Group noWrap spacing="xs" w="100%">
            <BuyerLogo
              buyerId={Number(id)}
              logoURL={logoURL}
              size="sm"
              radius="sm"
            />
            <Text
              variant="sm"
              fw={500}
              c="dark.7"
              sx={{ whiteSpace: 'pre-wrap' }}
              lineClamp={2}
            >
              {concatPostalCode(originalname, postalCode)}
            </Text>
          </Group>
          <Tooltip content={`${tenderCount} appels d'offres`}>
            <Text
              pr={8}
              variant="xs"
              fw={500}
              c="gray.6"
              miw="26px"
              align="right"
              sx={{ whiteSpace: 'nowrap' }}
            >
              {truncatedTenderCount}
            </Text>
          </Tooltip>
        </Group>
      </Box>
    );
  },
);
