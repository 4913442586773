import { Box, Text } from '@mantine/core';

export function SourceExtractPreview({ extract }: { extract: string }) {
  return (
    <Box
      sx={theme => ({
        backgroundColor: theme.colors.teal[0],
        padding: theme.spacing['04'],
        borderRadius: theme.radius.md,
      })}
    >
      <Text variant="sm" component="pre" sx={{ whiteSpace: 'pre-wrap' }}>
        {extract}
      </Text>
    </Box>
  );
}
