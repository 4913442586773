import { Box, Text } from '@mantine/core';

import { IconWand } from '@tabler/icons-react';
import { useNavigate } from 'react-router-dom';

import { NoticeTabValues } from '../../../../../app/pages/Notice.page';
import { useUrlHash } from '../../../../../shared/contexts/UrlHash.provider';
import { UrlHashBuilder } from '../../../../../shared/utils/navigation/UrlHashBuilder';
import { useOpenDetailPanel } from '../../../../notice/components/summarySheet/hooks/useOpenDetailPanel.hook';
import { useNoticeContext } from '../../../../notice/contexts/Notice.provider';
import { useSidePanelActions } from '../../../contexts/PannelAction.provider';

type SummaryCriterionAnswerPreviewCardProps = {
  question: string;
  criterionAnswerId: number;
};

export const SummaryCriterionAnswerPreviewCard = ({
  question,
  criterionAnswerId,
}: SummaryCriterionAnswerPreviewCardProps) => {
  const { closeSidePanel } = useSidePanelActions();
  const navigate = useNavigate();
  const { getRedirectUrl } = useUrlHash();
  const { tender } = useNoticeContext();

  const { openSidepanel } = useOpenDetailPanel(tender.id);
  const handleClick = () => {
    closeSidePanel();
    const currentUrl = new URL(location.href);

    currentUrl.searchParams.set('tab', NoticeTabValues.SUMMARY_SHEET);
    const newUrl = getRedirectUrl(
      currentUrl.pathname + currentUrl.search,
      new UrlHashBuilder().addSummarySheetActionData(criterionAnswerId).build(),
    );

    openSidepanel(criterionAnswerId);
    navigate(newUrl);
  };
  return (
    <Box
      p="2px"
      sx={theme => ({
        position: 'relative',
        display: 'inline-block',
        '::before': {
          zIndex: 1,
          content: '""',
          position: 'absolute',
          top: '0px',
          left: '0px',
          width: '100%',
          height: '100%',
          background: theme.other.gradients.aiHorizontalLight,
          borderRadius: '10px',
        },
      })}
    >
      <Box
        p="01"
        sx={theme => ({
          ':hover': {
            cursor: 'pointer',
            boxShadow: theme.shadows.lg,
          },
          position: 'relative',
          background: 'white',
          zIndex: 2,
          borderRadius: theme.radius.md,
          boxShadow: theme.shadows.xs,
        })}
        onClick={handleClick}
      >
        <Text
          px="03"
          py="02"
          variant="xs"
          fw={500}
          c="violet.8"
          sx={theme => ({
            background: `linear-gradient(264.41deg, rgba(204, 93, 232, 0.04) 7.47%, rgba(107, 97, 230, 0.04) 47.85%, rgba(26, 101, 229, 0.04) 98.22%);
`,
            borderRadius: theme.radius.md,
          })}
        >
          <IconWand size={16} style={{ marginRight: '4px' }} />
          <span
            style={{
              verticalAlign: 'top',
            }}
          >
            {question}
          </span>
        </Text>
      </Box>
    </Box>
  );
};
