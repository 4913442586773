import { Stack, Text, Title } from '@mantine/core';

import { updateSummaryCriterionAnswer } from '../../../../../../shared/api/magellan/summarySheet';
import type { SummaryCriterionAnswer } from '../../../../../../shared/entities/SummaryCriterionAnswer';
import { AnswerElement } from '../AnswerElement';

type CriterionElementProps = {
  criterionAnswer: SummaryCriterionAnswer;
  tenderId: number;
};
export function CriterionElement({
  criterionAnswer,
  tenderId,
}: CriterionElementProps) {
  return (
    <Stack spacing="04">
      <Stack spacing="02">
        <Title order={3}>{criterionAnswer.title}</Title>
        <Text variant="md" fw={400} c="gray.5">
          {criterionAnswer.originalQuestion}
        </Text>
      </Stack>
      <AnswerElement
        criterion={criterionAnswer}
        tenderId={tenderId}
        mutationOptions={{
          mutationKey: [updateSummaryCriterionAnswer.name, 'panel'],
        }}
      />
    </Stack>
  );
}
