import { useMutation, useQueryClient } from '@tanstack/react-query';

import {
  getSummaryCriteria,
  replaceAllSummaryCriteria,
} from '../../../../../shared/api/magellan/summarySheet';
import type { SummaryCriterion } from '../../../../../shared/entities/SummaryCriterion';
import { sanitizeCriteria } from '../utils/sanitizeCriteria';

export function useReplaceAllCriteriaMutation() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: [replaceAllSummaryCriteria.name],
    mutationFn: (criteria: SummaryCriterion[]) =>
      replaceAllSummaryCriteria(sanitizeCriteria(criteria)),

    onSuccess: () => {
      return queryClient.invalidateQueries({
        queryKey: [getSummaryCriteria.name],
      });
    },
  });
}
