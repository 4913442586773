import type { ChangeEvent } from 'react';
import { useState } from 'react';

import { Text, Title, createStyles } from '@mantine/core';

import { IconSearch } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';

import { Button } from '../../../../../shared/components/UI/Button/Button';
import { TextInput } from '../../../../../shared/components/UI/TextInput/TextInput';

import { useNoticeContext } from '../../../contexts/Notice.provider';
import { useOnTheFlyQuestionMutation } from '../hooks/useOnTheFlyQuestionMutation';

const styles = createStyles(theme => ({
  container: {
    border: `1px solid ${theme.colors.gray[1]}`,
    borderRadius: theme.radius.md,
    padding: theme.spacing['05'],
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing['04'],
    boxShadow: theme.shadows.xs,
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing['01'],
  },
  subtitle: {
    fontWeight: 400,
  },
  icon: {
    color: theme.colors.primary[7],
  },
  inputContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    alignItems: 'center',
    gap: theme.spacing['00'],
    border: `1px solid ${theme.colors.gray[2]}`,
    borderRadius: theme.radius.md,
    padding: `${theme.spacing['01']} ${theme.spacing['01']} ${theme.spacing['01']} ${theme.spacing['03']}`,
    boxShadow: theme.shadows.xs,
  },
  input: {
    flex: 1,
  },
}));

export function OnTheFlyQuestion() {
  const { classes } = styles();
  const { t } = useTranslation('summarySheet');
  const { tender } = useNoticeContext();
  const onTheFlyQuestionMutation = useOnTheFlyQuestionMutation();
  const [inputValue, setInputValue] = useState('');

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const handleSubmit = () => {
    onTheFlyQuestionMutation.mutate({
      tenderId: tender.id,
      question: inputValue,
    });
    setInputValue('');
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && inputValue) {
      event.stopPropagation();
      handleSubmit();
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <Title order={5}>{t('onTheFlyQuestion.title')}</Title>
        <Text variant="sm" className={classes.subtitle}>
          {t('onTheFlyQuestion.subtitle')}
        </Text>
      </div>
      <div className={classes.inputContainer}>
        <IconSearch size={20} className={classes.icon} />
        <TextInput
          variant="white"
          placeholder={t('onTheFlyQuestion.placeholder')}
          className={classes.input}
          value={inputValue}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
        />
        <Button disabled={!inputValue} onClick={handleSubmit}>
          {t('onTheFlyQuestion.cta')}
        </Button>
      </div>
    </div>
  );
}
