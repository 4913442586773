import { useMutation } from '@tanstack/react-query';

import { getTender, requestDCE } from '../../../shared/api/magellan/tender';
import { queryClient } from '../../../shared/infra/queryClient';

export function useSendDCERequest() {
  const res = useMutation({
    mutationFn: ({ tenderId }: { tenderId: number }) => requestDCE(tenderId),
    onSuccess: (_data, { tenderId }) => invalidateTenderQuery(tenderId),
  });

  return { sendDCERequest: res.mutate, ...res };
}

function invalidateTenderQuery(tenderId: number) {
  const queryKey = [getTender.name, tenderId];
  queryClient.invalidateQueries({ queryKey });
}
