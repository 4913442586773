import type { ReactNode } from 'react';

import type { HoverCardProps, Sx } from '@mantine/core';
import { HoverCard, Text, createStyles } from '@mantine/core';
import type { HoverCardDropdownProps } from '@mantine/core';

export type TooltipProps = {
  content: string | ReactNode;
  isVisibile?: boolean;
  children: ReactNode;
  dropDownProps?: HoverCardDropdownProps & { sx?: Sx };
  sx?: Sx;
} & HoverCardProps;

const useStyles = createStyles((theme, isVisibile: boolean) => ({
  dropdown: {
    background: theme.colors.gray[9],
    color: 'white',
    padding: `${theme.spacing['01']} ${theme.spacing['01']}`,
    borderRadius: theme.radius.md,
    boxShadow: theme.shadows.md,
    visibility: isVisibile ? 'visible' : 'hidden',
  },
}));

export function Tooltip({
  content,
  children,
  isVisibile = true,
  dropDownProps,
  classNames,
  ...rest
}: TooltipProps) {
  const { classes, cx } = useStyles(isVisibile);
  return (
    <HoverCard
      withinPortal
      position="bottom"
      openDelay={400}
      classNames={{ dropdown: cx(classes.dropdown, classNames?.dropdown) }}
      {...rest}
    >
      <HoverCard.Target>{children}</HoverCard.Target>
      <HoverCard.Dropdown {...dropDownProps}>
        {typeof content === 'string' ? (
          <Text variant="xs" fw="500" c="white">
            {content}
          </Text>
        ) : (
          content
        )}
      </HoverCard.Dropdown>
    </HoverCard>
  );
}
