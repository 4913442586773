import { Box, Stack, Text } from '@mantine/core';

import { useTranslation } from 'react-i18next';

import { Avatar } from '../../../../../../shared/components/UI/Avatar/Avatar';

import type { RichTextJson } from '../../../../../../shared/entities/Comment';
import type { Thread } from '../../../../../../shared/entities/Thread';
import { useActiveUserQuery } from '../../../../../../shared/hooks/useActiveUserQuery.hook';
import { CommentInput } from '../../../../../collaboration/components/comments/components/CommentInput';
import { ThreadComponent } from '../../../../../collaboration/components/comments/components/ThreadComponent';
import { useCreateCommentQuery } from '../../../../../collaboration/components/comments/hooks/useCreateCommentQuery.hook';

type SummarySheetCollaborationProps = {
  thread?: Thread | null;
  summaryCriterionAnswerId: number;
  tenderId: number;
};

export function SummarySheetCollaboration({
  thread,
  summaryCriterionAnswerId,
  tenderId,
}: SummarySheetCollaborationProps) {
  const { t } = useTranslation(['collaboration']);

  return (
    <Stack spacing="03" w="100%" id="summarySheetCollaboration">
      <Text variant="xs" fw={500} c="gray.4">
        {t('comments')}
      </Text>
      <Box pl="05">
        <SummarySheetThread
          thread={thread}
          summaryCriterionAnswerId={summaryCriterionAnswerId}
          tenderId={tenderId}
        />
      </Box>
    </Stack>
  );
}

type SummarySheetThreadProps = {
  thread?: Thread | null;
  summaryCriterionAnswerId: number;
  tenderId: number;
};

function SummarySheetThread({
  thread,
  summaryCriterionAnswerId,
  tenderId,
}: SummarySheetThreadProps) {
  const { t } = useTranslation(['collaboration']);
  const activeUserQuery = useActiveUserQuery();
  const { createThread } = useCreateCommentQuery(tenderId);

  const { user: activeUser } = activeUserQuery.data;

  if (!thread) {
    return (
      <CommentInput
        variant="subtle"
        placeholder={t('addComment')}
        showBorderOnFocus
        avatar={
          <Avatar color={activeUser.avatarColor}>
            {activeUser.firstName[0].toUpperCase() +
              activeUser.lastName[0].toUpperCase()}
          </Avatar>
        }
        saveComment={(content: RichTextJson) => {
          createThread({
            content,
            summaryCriterionAnswerId,
          });
        }}
      />
    );
  }

  return (
    <ThreadComponent
      thread={thread}
      px="0"
      showBorderOnFocus
      tenderId={tenderId}
    />
  );
}
