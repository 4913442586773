import { useMantineTheme } from '@mantine/core';

import { IconDownload } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';

import { Button } from './UI/Button/Button';

import { downloadFile } from '../utils/download';

type DownloadButtonProps = {
  fileURL: string;
  prettyName: string;
};

export function DownloadFileButton({
  fileURL,
  prettyName,
}: DownloadButtonProps) {
  const theme = useMantineTheme();
  const { t } = useTranslation();

  return (
    <Button
      variant="default"
      color="primary"
      size="sm"
      leftIcon={<IconDownload color={theme.colors.primary[7]} />}
      onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
        downloadFile(fileURL, prettyName);
        e.stopPropagation();
      }}
    >
      {t('download')}
    </Button>
  );
}
