import type { AssessmentStatus } from './AssessmentStatus';
import type { DataPointSource } from './DataPointMeta';
import type { Thread } from './Thread';
import type { User } from './User';

export enum AiRelevanceFeedbackStatus {
  GOOD = 'GOOD',
  BAD = 'BAD',
}

export type InstantAnalysisAnswer = {
  id: number;
  userQuestion: string;
  instantAnalysisStatus: InstantAnalysisStatus;
  questionsSetTitle: string;

  llmAnswer: string | null;
  sources: DataPointSource[];

  assessment?: AssessmentStatus | null;
  assessedAt?: string;
  assessorId?: number;

  userAnswer: string | null;
  userAnswerCreatedAt: string | null;
  userAnswerCreatedBy: Omit<User, 'email' | 'id'> | null;

  aiRelevanceFeedback?: AiRelevanceFeedbackStatus | null;
  thread?: Thread | null;
};

export type InstantAnalysisStatus =
  | 'WAITING'
  | 'PENDING'
  | 'RUNNING'
  | 'DONE'
  | 'FAILED'
  | 'NO_SUPPORTED_DOCUMENTS';

export type OnTheFlyAnswer = Omit<
  InstantAnalysisAnswer,
  'questionsSetTitle'
> & {
  questionsSetTitle: null;
};

export type AssessmentRecap = {
  blockingPoint: number;
  attentionPoint: number;
  positivePoint: number;
  neutralPoint: number;
  unAssessed: number;
};

export type Answer = InstantAnalysisAnswer | OnTheFlyAnswer;
