import type { UseMutationOptions } from '@tanstack/react-query';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import {
  getSummaryCriterionAnswers,
  getSummaryCriterionDetail,
  updateSummaryCriterionAnswer,
} from '../../../../../shared/api/magellan/summarySheet';
import type { SummaryCriterionAnswer } from '../../../../../shared/entities/SummaryCriterionAnswer';

type UpdateSummaryCriterionAnswerProps = {
  tenderId: number;
  criterionId: number;
  manualAnswer: string | null;
};

export type UpdateSummaryCriterionAnswerMutationOptions = UseMutationOptions<
  SummaryCriterionAnswer,
  unknown,
  UpdateSummaryCriterionAnswerProps
>;

export function useUpdateSummaryCritetionAnswer(
  options?: UpdateSummaryCriterionAnswerMutationOptions,
) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({
      tenderId,
      criterionId,
      manualAnswer,
    }: UpdateSummaryCriterionAnswerProps) =>
      await updateSummaryCriterionAnswer(tenderId, criterionId, manualAnswer),
    onSuccess: (_data, { tenderId, criterionId }) => {
      queryClient.invalidateQueries({
        queryKey: [getSummaryCriterionAnswers.name, tenderId],
      });
      queryClient.invalidateQueries({
        queryKey: [getSummaryCriterionDetail.name, tenderId, criterionId],
      });
    },
    ...options,
  });
}
