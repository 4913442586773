import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import {
  createComment,
  createThread,
} from '../../../../../shared/api/magellan/comments';
import type { RichTextJson } from '../../../../../shared/entities/Comment';
import { useOnTheFlyQuestionsSetsAnalysis } from '../../../../../shared/hooks/useOnTheFlyQuestionAnalysis.hook';
import { useQuestionsSetsAnalysis } from '../../../../../shared/hooks/useQuestionsSetsAnalysis.hook';
import { queryClient } from '../../../../../shared/infra/queryClient';
import { handleError } from '../utils/handleCommentError';

type CreateThreadType = {
  content: RichTextJson;
  threadId?: number;
  instantAnalysisAnswerId?: number;
  summaryCriterionAnswerId?: number;
};

export function useCreateCommentQuery(tenderId: number) {
  const { t } = useTranslation('collaboration');
  const { refetch: refetchAnalysis } = useQuestionsSetsAnalysis(tenderId);
  const { refetch: refetchOnTheFlyAnalysis } =
    useOnTheFlyQuestionsSetsAnalysis(tenderId);
  const refetch = () => queryClient.invalidateQueries({ queryKey: [] });

  const threadMutation = useMutation({
    mutationFn: ({
      content,
      threadId,
      instantAnalysisAnswerId,
      summaryCriterionAnswerId,
    }: CreateThreadType) => {
      if (threadId) {
        return createComment(
          tenderId,
          threadId,
          content,
          instantAnalysisAnswerId,
          summaryCriterionAnswerId,
        );
      }
      return createThread(
        tenderId,
        content,
        instantAnalysisAnswerId,
        summaryCriterionAnswerId,
      );
    },
    onSuccess: (_data, variable) => {
      refetch();
      if (variable.instantAnalysisAnswerId) {
        refetchAnalysis();
        refetchOnTheFlyAnalysis();
      }
    },
    onError: error => {
      handleError(error, t('errorMessages.createComment'));
    },
  });

  return {
    createThread: (props: Omit<CreateThreadType, 'threadId'>) =>
      threadMutation.mutate(props),
    createComment: (props: CreateThreadType) => threadMutation.mutate(props),
  };
}
