import { useEffect } from 'react';

import { ScrollArea, Stack } from '@mantine/core';

import { DOCUMENT_SIDE_PANEL_WIDTH } from '../../../../../../shared/components/SourcesSection/DocumentSidePanel';
import { SourcesSection } from '../../../../../../shared/components/SourcesSection/SourcesSection';
import { useUrlHash } from '../../../../../../shared/contexts/UrlHash.provider';
import { UrlHashTypes } from '../../../../../../shared/utils/navigation/UrlHashBuilder';
import { useGetSummaryCriterionDetail } from '../../hooks/useGetSummaryCriterionDetail.hook';
import { CriterionElement } from './CriterionElement';
import { EditedAtRow } from './EditedAtRow';
import { SummarySheetCollaboration } from './SummarySheetCollaboration';

const MIN_MARGIN_LEFT_TO_ENABLE_USER_TO_CLICK_OUTSIDE = 20;
type AnswerDetailPanelProps = {
  tenderId: number;
  criterionAnswerId: number;
  closePanel: () => void;
};

export function AnswerDetailPanel({
  tenderId,
  criterionAnswerId,
}: AnswerDetailPanelProps) {
  const criterionAnswerDetailQuery = useGetSummaryCriterionDetail(
    tenderId,
    criterionAnswerId,
  );

  const {
    manualAnswerCreatedBy: user,
    manualAnswerCreatedAt: createdAt,
    thread,
    llmUsedSources,
  } = criterionAnswerDetailQuery.data;

  const { getReceivedUrlHashParam } = useUrlHash();
  const receivedUrlHashParam = getReceivedUrlHashParam(
    UrlHashTypes.SUMMARY_SHEET_ACTION,
  );

  useEffect(() => {
    if (receivedUrlHashParam) {
      const summarySheetCollaboration = document.getElementById(
        'summarySheetCollaboration',
      );
      if (summarySheetCollaboration) {
        summarySheetCollaboration.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
      }
    }
  }, [receivedUrlHashParam]);

  return (
    <ScrollArea h="100%" sx={{ flexGrow: 1 }}>
      <Stack
        p="04"
        pt={0}
        spacing="04"
        w={`calc(100vw - ${DOCUMENT_SIDE_PANEL_WIDTH + MIN_MARGIN_LEFT_TO_ENABLE_USER_TO_CLICK_OUTSIDE}px)`}
        miw={420}
        maw={856}
      >
        <CriterionElement
          criterionAnswer={criterionAnswerDetailQuery.data}
          tenderId={tenderId}
        />
        {user && createdAt && (
          <EditedAtRow
            user={user}
            date={createdAt}
            thread={thread}
            criterionAnswerId={criterionAnswerId}
            tenderId={tenderId}
          />
        )}
        {llmUsedSources && llmUsedSources.length > 0 && (
          <SourcesSection sources={llmUsedSources} tenderId={tenderId} />
        )}
        <SummarySheetCollaboration
          thread={thread}
          summaryCriterionAnswerId={criterionAnswerId}
          tenderId={tenderId}
        />
      </Stack>
    </ScrollArea>
  );
}
