import { Box } from '@mantine/core';

import type { RichTextJson } from '../../../../../shared/entities/Comment';
import { useNoticeContext } from '../../../../notice/contexts/Notice.provider';
import { useCreateCommentQuery } from '../hooks/useCreateCommentQuery.hook';
import { CommentInput } from './CommentInput';

export function NewThreadInput() {
  const { tender } = useNoticeContext();
  const { createThread } = useCreateCommentQuery(tender.id);

  return (
    <Box p="04">
      <CommentInput
        saveComment={(content: RichTextJson) => createThread({ content })}
        placeholder={'Ajouter un commentaire'}
      />
    </Box>
  );
}
