import { DocX } from './DocX';
import { FileNotSupported } from './FileNotSupported';
import { Image } from './Image';
import { PDF } from './PDF';

const SUPPORTED_IMAGE_EXTENSIONS = ['png', 'jpg', 'jpeg', 'gif', 'webp'];
export const SUPPORTED_OFFICE_EXTENSIONS = [
  'ppt',
  'pptx',
  'doc',
  'docx',
  'xls',
  'xlsx',
  'odt',
];

export function DocumentPreview({
  search,
  initialPage = 1,
  fileURL,
  prettyName,
}: {
  search?: string;
  initialPage?: number;
  fileURL: string;
  prettyName: string;
}) {
  const extension = fileURL.split('.').pop()?.toLowerCase() || '';

  if (extension === 'pdf') {
    return <PDF fileUrl={fileURL} initialPage={initialPage} search={search} />;
  }

  if (SUPPORTED_OFFICE_EXTENSIONS.includes(extension)) {
    return <DocX fileUrl={fileURL} initialPage={initialPage} />;
  }

  if (SUPPORTED_IMAGE_EXTENSIONS.includes(extension)) {
    return <Image fileUrl={fileURL} />;
  }

  return <FileNotSupported fileURL={fileURL} prettyName={prettyName} />;
}
