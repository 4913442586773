import { Box, useMantineTheme } from '@mantine/core';

import { CriterionAnswerType } from '../../../notice/components/summarySheet/utils/getAnswerType';

type AnswerLeftBorderProps = {
  variant?: CriterionAnswerType;
};

export const AnswerLeftBorder = ({
  variant = CriterionAnswerType.LLM,
}: AnswerLeftBorderProps) => {
  const innerGradient = useInnerGradient(variant);
  const outerGradient =
    'linear-gradient(264.41deg, rgba(204, 93, 232, 0.1) 7.47%, rgba(107, 97, 230, 0.1) 47.85%, rgba(26, 101, 229, 0.1) 98.22%)';

  return (
    <Box
      miw="6px"
      bg={outerGradient}
      py="3px"
      sx={theme => ({
        display: 'flex',
        borderRadius: theme.radius.md,
        justifyContent: 'center',
        alignItems: 'center',
      })}
    >
      <Box
        miw="2px"
        h="100%"
        bg={innerGradient}
        sx={theme => ({
          borderRadius: theme.radius.lg,
        })}
      />
    </Box>
  );
};

const useInnerGradient = (variant: CriterionAnswerType) => {
  const theme = useMantineTheme();
  const innerGradient: Record<CriterionAnswerType, string> = {
    llm: theme.other.gradients.aiVertical,
    user: 'linear-gradient(264.41deg, #7CC2FB 7.47%, #4EA7F7 47.85%, #1951C6 98.22%)',
    notFound: 'gray.1',
  };

  return innerGradient[variant];
};
