import type { SummaryCriterionAnswer } from '../../../../../shared/entities/SummaryCriterionAnswer';

export enum CriterionAnswerType {
  USER = 'user',
  NOT_FOUND = 'notFound',
  LLM = 'llm',
}

export function getAnswerType(
  criterion: SummaryCriterionAnswer,
): CriterionAnswerType {
  const isLlmAnswerDeleted = criterion.manualAnswer === '';

  if (
    criterion.manualAnswer &&
    criterion.manualAnswer !== criterion.llmAnswer
  ) {
    return CriterionAnswerType.USER;
  } else if (isLlmAnswerDeleted || !criterion.llmAnswer) {
    return CriterionAnswerType.NOT_FOUND;
  } else {
    return CriterionAnswerType.LLM;
  }
}
