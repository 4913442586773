import { ActionIcon, Box, Group, Skeleton, Text } from '@mantine/core';
import { useHover } from '@mantine/hooks';

import { IconList, IconMessageCircle2 } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';

import { Button } from '../../../../../shared/components/UI/Button/Button';

import { updateSummaryCriterionAnswer } from '../../../../../shared/api/magellan/summarySheet';
import type { SummaryCriterionAnswerWithNumberOfComments } from '../../../../../shared/entities/SummaryCriterionAnswer';
import { useNoticeContext } from '../../../contexts/Notice.provider';
import { useOpenDetailPanel } from '../hooks/useOpenDetailPanel.hook';
import { AnswerElement } from './AnswerElement';

type CriterionRowProps = {
  criterion: SummaryCriterionAnswerWithNumberOfComments;
};

export const CriterionRow = ({ criterion }: CriterionRowProps) => {
  const { ref: hoverRef, hovered } = useHover();
  const { tender } = useNoticeContext();
  const { openSidepanel } = useOpenDetailPanel(tender.id);

  const handleOpenSidepanel = () => {
    openSidepanel(criterion.id);
  };

  return (
    <Group ref={hoverRef} noWrap pt="02" spacing="03">
      <Group noWrap spacing="01" w="200px" miw="200px" align="center">
        {criterion.numberOfComments > 0 ? (
          <ActionIcon c="blue.7" onClick={handleOpenSidepanel}>
            <IconMessageCircle2 size={16} />
          </ActionIcon>
        ) : (
          <Box miw="05" mx="00" />
        )}
        <Text variant="sm" fw={500} c="gray.9">
          {criterion.title}
        </Text>
      </Group>
      {criterion.ragStatus === 'RUNNING' ? (
        <Skeleton h="09" />
      ) : (
        <AnswerColumn
          criterion={criterion}
          isRowHovered={hovered}
          handleOpenSidepanel={handleOpenSidepanel}
        />
      )}
    </Group>
  );
};

type AnswerColumnProps = {
  criterion: SummaryCriterionAnswerWithNumberOfComments;
  isRowHovered: boolean;
  handleOpenSidepanel: () => void;
};

function AnswerColumn({
  criterion,
  isRowHovered,
  handleOpenSidepanel,
}: AnswerColumnProps) {
  const { t } = useTranslation(['common']);
  const { tender } = useNoticeContext();

  return (
    <Group noWrap position="apart" sx={{ flexGrow: 2 }}>
      <AnswerElement
        criterion={criterion}
        tenderId={tender.id}
        mutationOptions={{
          mutationKey: [updateSummaryCriterionAnswer.name, 'mainPage'],
        }}
      />
      {isRowHovered ? (
        <Button
          variant="outline"
          w="118px"
          color="gray"
          leftIcon={<IconList />}
          onClick={handleOpenSidepanel}
        >
          {t('open')}
        </Button>
      ) : (
        <Box w="118px" />
      )}
    </Group>
  );
}
