import { Group, Stack, Text, Title } from '@mantine/core';
import { useForm } from '@mantine/form';
import type { ContextModalProps } from '@mantine/modals';

import { useTranslation } from 'react-i18next';

import { Button } from '../UI/Button/Button';
import { TextArea } from '../UI/TextArea/TextArea';

import type { DecisionStatus } from '../../entities/Interaction';

type LossReasonModalProps = {
  onDecision: (status: DecisionStatus, reason?: string) => void;
  nextStatus: DecisionStatus;
};

type FormValues = {
  reason: string;
};

export const LossReasonModal = ({
  context,
  id,
  innerProps,
}: ContextModalProps<LossReasonModalProps>) => {
  const { t } = useTranslation();

  const form = useForm<FormValues>({
    initialValues: {
      reason: '',
    },
    validate: {
      reason: (value: string) => (value.trim() ? null : t('fieldRequired')),
    },
  });

  const onSubmit = async (values: FormValues) => {
    if (!form.validate().hasErrors) {
      innerProps.onDecision(innerProps.nextStatus, values.reason);
      context.closeModal(id);
    }
  };

  return (
    <form onSubmit={form.onSubmit(onSubmit)}>
      <Stack py="04" px="05" spacing="04">
        <Title order={5} mb="xs">
          {t('modals.lossReasonModal.title')}
        </Title>
        <Text variant="sm" fw="400" c="gray.6">
          {t('modals.lossReasonModal.description')}
        </Text>

        <TextArea {...form.getInputProps('reason')} />

        <Group noWrap position="right" spacing="03">
          <Button
            variant="subtle"
            c="primary.9"
            onClick={() => {
              context.closeModal(id);
            }}
          >
            {t('cancel')}
          </Button>
          <Button size="md" type="submit">
            {t('send')}
          </Button>
        </Group>
      </Stack>
    </form>
  );
};
