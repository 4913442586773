import { useEffect, useState } from 'react';

import * as DocumentSidePanel from '../components/SourcesSection/DocumentSidePanel';
import { useSidepanel } from '../contexts/Sidepanel.provider';
import type { DataPointSource } from '../entities/DataPointMeta';
import { useTenderQuery } from './useTenderQuery.hook';

export function useSourceSidePanel({
  sources,
  tenderId,
}: {
  sources: DataPointSource[];
  tenderId: number;
}) {
  const { togglePanel, closePanel, updatePanel } = useSidepanel({
    onClose() {
      setSelectedSource(undefined);
      setIsPanelOpen(false);
    },
  });

  const tenderQuery = useTenderQuery(Number(tenderId));
  const [selectedSource, setSelectedSource] = useState<DataPointSource>();
  const [isPanelOpen, setIsPanelOpen] = useState(false);

  useEffect(() => {
    if (!selectedSource) return;

    const tenderDocuments = tenderQuery.data?.documents ?? [];
    const document = tenderDocuments.find(
      doc => doc.originalName === selectedSource.docFilePath,
    );

    if (!document || !tenderId) return;

    const panelContent = {
      header: (
        <DocumentSidePanel.Header
          document={document}
          source={selectedSource}
          sources={sources}
          onSourceChange={setSelectedSource}
        />
      ),
      content: (
        <DocumentSidePanel.Content
          tenderId={Number(tenderId)}
          document={document}
          source={selectedSource}
          onClose={closePanel}
        />
      ),
    };

    if (isPanelOpen) {
      updatePanel(panelContent);
    } else {
      togglePanel(panelContent);
      setIsPanelOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isPanelOpen,
    selectedSource,
    sources,
    tenderId,
    tenderQuery.data?.documents,
    /**
     * ! TODO fix this in the sidepanel provider
     *
     * The list of dependencies cannot be exhaustive, due to how the sidepanel is managed.
     * If we make the dependencies exhaustive, we trigger infinite re-renders
     * Ideally, a refacto of the sidepanel would be needed to avoid problems in the fututre
     *
     * missing deps:
     * - closePanel
     * - togglePanel
     * - updatePanel
     *
     **/
  ]);

  const openSourcePanel = (source: DataPointSource) => {
    setSelectedSource(source);
  };

  return { openSourcePanel, selectedSource };
}
