import { useState } from 'react';

import { Group, NumberInput, Stack, Text, Title } from '@mantine/core';
import { useForm } from '@mantine/form';
import type { ContextModalProps } from '@mantine/modals';

import { useTranslation } from 'react-i18next';

import { Button } from '../UI/Button/Button';
import { TextArea } from '../UI/TextArea/TextArea';

import type { DecisionStatus } from '../../entities/Interaction';

type WinReasonModalProps = {
  onDecision: (
    status: DecisionStatus,
    reason?: string,
    winningAmount?: number,
  ) => void;
  nextStatus: DecisionStatus;
  tenderId: number;
};

type FormValues = {
  reason: string;
  amount: number | undefined;
};

export const WinReasonModal = ({
  context,
  id,
  innerProps,
}: ContextModalProps<WinReasonModalProps>) => {
  const { t } = useTranslation();
  const [isFocused, setIsFocused] = useState(false);

  const form = useForm<FormValues>({
    initialValues: {
      reason: '',
      amount: undefined,
    },
    validate: {
      amount: value =>
        Number(value) > 0
          ? null
          : t('modals.winReasonModal.amountInputValidationMessage'),
      reason: (value: string) => (value.trim() ? null : t('fieldRequired')),
    },
  });

  const numberInputHasError = form.errors.amount;

  const onSubmit = async (values: FormValues) => {
    if (!form.validate().hasErrors) {
      innerProps.onDecision(
        innerProps.nextStatus,
        values.reason,
        values.amount,
      );
      context.closeModal(id);
    }
  };

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  return (
    <form onSubmit={form.onSubmit(onSubmit)}>
      <Stack py="04" px="05" spacing="04">
        <Title order={5} mb="xs">
          {t('modals.winReasonModal.title')}
        </Title>
        <Text variant="sm" fw="400" c="gray.6">
          {t('modals.winReasonModal.description')}
        </Text>

        <div style={{ position: 'relative' }}>
          <NumberInput
            sx={theme => ({
              label: {
                fontWeight: 500,
                fontSize: theme.fontSizes.sm,
                lineHeight: '20px',
                marginBottom: theme.spacing['02'],
              },
              input: {
                height: '20px',
                minHeight: '20px',
                padding: 0,
                border: 'none',
                background: 'transparent',
                margin: 'auto',
                '::placeholder': {
                  color: theme.colors.gray[4],
                  fontWeight: 400,
                  fontSize: theme.fontSizes.sm,
                },
                '::placeholder:error': {
                  color: theme.colors.green[5],
                },
                ':disabled': {
                  background: 'transparent',
                  color: theme.colors.gray[3],
                },
              },
            })}
            styles={theme => ({
              wrapper: {
                display: 'flex',
                height: '36px',
                paddingRight: theme.spacing['03'],
                paddingLeft: theme.spacing['03'],
                paddingTop: '3px',
                paddingBottom: '3px',
                borderRadius: theme.radius.md,
                background: 'white',
                outline: numberInputHasError
                  ? `2px solid ${theme.colors.red[5]}`
                  : isFocused
                    ? `2px solid ${theme.colors.blue[6]}`
                    : `2px solid ${theme.colors.gray[2]}`,
              },
            })}
            max={99999999999}
            placeholder="3 000 000"
            formatter={value => value.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
            label={t('modals.winReasonModal.amountInputLabel')}
            hideControls
            onKeyDown={event => {
              if (!/[0-9]|\Backspace|Tab/.test(event.key)) {
                event.preventDefault();
              }
            }}
            {...form.getInputProps('amount')}
            onFocus={handleFocus}
            onBlur={handleBlur}
          />

          <Text
            variant="sm"
            fw="600"
            c="gray.6"
            sx={theme => ({
              position: 'absolute',
              right: theme.spacing['02'],
              top: theme.spacing['07'],
            })}
          >
            {t('modals.winReasonModal.currency')}
          </Text>
        </div>

        <TextArea
          label={t('modals.winReasonModal.reasonInputLabel')}
          placeholder={t('modals.winReasonModal.reasonInputPlaceholder')}
          {...form.getInputProps('reason')}
        />

        <Group noWrap position="right" spacing="03">
          <Button
            variant="subtle"
            onClick={() => {
              context.closeModal(id);
            }}
          >
            {t('cancel')}
          </Button>
          <Button size="md" type="submit">
            {t('send')}
          </Button>
        </Group>
      </Stack>
    </form>
  );
};
