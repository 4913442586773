import { useSuspenseQuery } from '@tanstack/react-query';

import { getSummaryCriterionDetail } from '../../../../../shared/api/magellan/summarySheet';

export function useGetSummaryCriterionDetail(
  tenderId: number,
  criterionAnswerId: number,
) {
  return useSuspenseQuery({
    queryKey: [getSummaryCriterionDetail.name, tenderId, criterionAnswerId],
    queryFn: () => getSummaryCriterionDetail(tenderId, criterionAnswerId),
  });
}
