import { Group, Text, ThemeIcon } from '@mantine/core';

import { IconEye } from '@tabler/icons-react';

export function SourceButton({
  label,
  onClick,
  isActive,
}: {
  label: string;
  onClick: () => void;
  isActive: boolean;
}) {
  return (
    <Group
      onClick={onClick}
      sx={theme => ({
        flexWrap: 'nowrap',
        maxWidth: '264px',
        gap: theme.spacing['02'],
        padding: `${theme.spacing['01']} ${theme.spacing['02']}`,
        borderRadius: theme.radius.md,
        border: `1px solid`,
        borderColor: isActive ? theme.colors.primary[7] : theme.colors.gray[1],
        cursor: 'pointer',
        ':hover': { backgroundColor: theme.colors.gray[0] },
      })}
    >
      <Text variant="sm" fw={500} c="gray.6" truncate>
        {label}
      </Text>
      <ThemeIcon
        size={16}
        color="gray.6"
        variant="outline"
        sx={{ border: 'none' }}
      >
        <IconEye />
      </ThemeIcon>
    </Group>
  );
}
