import { Box } from '@mantine/core';

import { SlideInPanel } from '../../../../../shared/components/UI/SlideInPanel/SlideInPanel';

import { DocumentPreview } from '../../../../../shared/components/DocumentPreview/DocumentPreview';
import { DownloadFileButton } from '../../../../../shared/components/DownloadFileButton';
import type { Document } from '../../../../../shared/entities/Document';

type DocumentPreviewPanelProps = {
  document: Document;
  isPanelOpen: boolean;
  onClose: () => void;
};

export function DocumentPreviewPanel({
  document,
  isPanelOpen,
  onClose,
}: DocumentPreviewPanelProps) {
  return (
    <SlideInPanel
      isOpen={isPanelOpen}
      onClose={onClose}
      title={document.prettyName}
      headerRight={
        <DownloadFileButton
          fileURL={document.fileURL}
          prettyName={document.prettyName}
        />
      }
    >
      <Box
        p="04"
        mx="04"
        h="100%"
        sx={theme => ({
          border: `1px solid ${theme.colors.gray[1]}`,
          borderRadius: theme.radius.md,
        })}
      >
        <DocumentPreview
          fileURL={document.fileURL}
          prettyName={document.prettyName}
        />
      </Box>
    </SlideInPanel>
  );
}
