import { useEffect } from 'react';

import { Box } from '@mantine/core';

import { Viewer, type ViewerProps, Worker } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { pageNavigationPlugin } from '@react-pdf-viewer/page-navigation';
import '@react-pdf-viewer/page-navigation/lib/styles/index.css';
import {
  type RenderHighlightsProps,
  searchPlugin,
} from '@react-pdf-viewer/search';
import '@react-pdf-viewer/search/lib/styles/index.css';

type PDFViewerProps = ViewerProps & {
  search?: string;
};

export function PDF({ search = '', initialPage = 1, ...rest }: PDFViewerProps) {
  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  const pageNavigationPluginInstance = pageNavigationPlugin();

  const searchPluginInstance = searchPlugin({
    keyword: sanitizeString(search),
    renderHighlights,
  });

  useEffect(() => {
    searchPluginInstance.setTargetPages(
      targetPage => targetPage.pageIndex === initialPage - 1,
    );
    searchPluginInstance.highlight(sanitizeString(search));
    /**
     * The dependency array cannot be exhaustive due to how the search plugin works.
     * Making it a Ref affects the the highlight behaviour
     * Missing deps: searchPluginInstance
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  useEffect(() => {
    pageNavigationPluginInstance.jumpToPage(initialPage - 1);
  }, [initialPage, pageNavigationPluginInstance]);

  return (
    <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
      <Box sx={{ height: '100%', overflow: 'hidden' }}>
        <Viewer
          plugins={[
            defaultLayoutPluginInstance,
            pageNavigationPluginInstance,
            searchPluginInstance,
          ]}
          initialPage={initialPage - 1}
          {...rest}
        />
      </Box>
    </Worker>
  );
}

function sanitizeString(input: string): string[] {
  const MIN_WORD_LENGTH = 15;

  return (
    input
      .split(/[\n]{1,}/)
      .map(str =>
        str
          .replace(/[\s]+/g, ' ')
          .trim()
          .replace(/^[^\w]+/g, ''),
      )
      /**
       * Remove words shorter than MIN_WORD_LENGTH to avoid highlighting too many words.
       * According to what was observed, it seems to be a good length.
       */
      .filter(el => el.length > MIN_WORD_LENGTH)
  );
}

function renderHighlights(renderProps: RenderHighlightsProps) {
  return (
    <>
      {renderProps.highlightAreas.map((area, index) => (
        <div
          key={`${area.pageIndex}-${index}`}
          style={{
            ...renderProps.getCssProperties(area),
            position: 'absolute',
            backgroundColor: 'rgba(206, 237, 222, 0.5)',
            transform: 'translate(-2px, -2px)',
            height: `calc(${renderProps.getCssProperties(area).height} + 1px)`,
            width: `calc(${renderProps.getCssProperties(area).width} + 1px)`,
          }}
        />
      ))}
    </>
  );
}
