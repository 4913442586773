import type { ReactElement } from 'react';

import { Divider, ScrollArea, Stack } from '@mantine/core';

import type { Thread } from '../../../../../shared/entities/Thread';
import { ThreadComponent } from './ThreadComponent';

type ThreadListProps = {
  threads: Thread[];
  tenderId: number;
};

export const ThreadList = ({ threads, tenderId }: ThreadListProps) => {
  const threadsWithSeparators: ReactElement[] = threads.reduce<ReactElement[]>(
    (acc, thread, index) => {
      acc.push(
        <ThreadComponent key={thread.id} thread={thread} tenderId={tenderId} />,
      );

      // Only add the separator if it's not the last item
      if (index < threads.length - 1) {
        acc.push(<Divider key={`separator-${thread.id}`} color="gray.0" />);
      }
      return acc;
    },
    [],
  );

  return (
    <ScrollArea h="100%" sx={{ flexGrow: 1 }}>
      <Stack spacing="04">{threadsWithSeparators}</Stack>
    </ScrollArea>
  );
};
