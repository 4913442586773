import { useEffect, useRef, useState } from 'react';

import { Group, Text, Textarea } from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';

import { useTranslation } from 'react-i18next';
import Markdown from 'react-markdown';

import type { SummaryCriterionAnswer } from '../../../../../shared/entities/SummaryCriterionAnswer';
import { AnswerLeftBorder } from '../../../../instantAnalysis/components/answers/AnswerLeftBorder';
import type { UpdateSummaryCriterionAnswerMutationOptions } from '../hooks/useUpdateSummaryCritetionAnswer.hook';
import { useUpdateSummaryCritetionAnswer } from '../hooks/useUpdateSummaryCritetionAnswer.hook';
import { getAnswerType } from '../utils/getAnswerType';

type AnswerElementProps = {
  criterion: SummaryCriterionAnswer;
  tenderId: number;
  mutationOptions?: UpdateSummaryCriterionAnswerMutationOptions;
};

export function AnswerElement({
  criterion,
  tenderId,
  mutationOptions,
}: AnswerElementProps) {
  const { mutate: updateSummaryCriterion } =
    useUpdateSummaryCritetionAnswer(mutationOptions);

  const answerType = getAnswerType(criterion);
  const initialAnswer = criterion.manualAnswer ?? criterion.llmAnswer;
  const [manualAnswer, setManualAnswer] = useState(initialAnswer ?? '');

  const [debouncedManualAnswer] = useDebouncedValue(manualAnswer, 300);
  const [isFocused, setIsFocused] = useState(false);

  useEffect(() => {
    setManualAnswer(initialAnswer ?? '');
  }, [initialAnswer, setManualAnswer]);

  useEffect(() => {
    if (!isFocused && manualAnswer.trim().length === 0) {
      setManualAnswer('');
    }
  }, [isFocused, manualAnswer]);

  useEffect(() => {
    if (
      debouncedManualAnswer !== null &&
      debouncedManualAnswer !== initialAnswer &&
      isFocused
    ) {
      updateSummaryCriterion({
        tenderId,
        criterionId: criterion.id,
        manualAnswer: debouncedManualAnswer,
      });
    }
  }, [
    criterion.id,
    debouncedManualAnswer,
    initialAnswer,
    isFocused,
    tenderId,
    updateSummaryCriterion,
  ]);

  const onInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newValue = e.currentTarget.value;
    setManualAnswer(newValue);
  };

  return (
    <Group
      spacing="04"
      w="100%"
      noWrap
      sx={{ alignItems: 'stretch', flexGrow: 2 }}
    >
      <AnswerLeftBorder variant={answerType} />
      <AnswerInput
        value={manualAnswer}
        onChange={onInputChange}
        setFocus={setIsFocused}
        isFocused={isFocused}
      />
    </Group>
  );
}

type AnswerInputProps = {
  value: string;
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  setFocus: (value: boolean) => void;
  isFocused: boolean;
};

const AnswerInput = ({
  value,
  onChange,
  setFocus,
  isFocused,
}: AnswerInputProps) => {
  const { t } = useTranslation('summarySheet');
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  if (isFocused) {
    return (
      <Textarea
        ref={textareaRef}
        variant="unstyled"
        h="fit-content"
        p="02"
        py="14px"
        w="100%"
        autosize
        value={value}
        placeholder={t('placeholder')}
        onChange={onChange}
        onFocus={() => setFocus(true)}
        onBlur={() => setFocus(false)}
        onKeyDown={e => {
          if (e.key === 'Escape') {
            setFocus(false);
          }
        }}
        styles={theme => ({
          input: {
            padding: '0px !important',
            overflowY: 'hidden',
            color: theme.colors.gray[9],
          },
        })}
        sx={theme => ({
          ':hover': {
            borderColor: theme.colors.gray[1],
            borderRadius: theme.spacing['03'],
          },
          border: `1px solid transparent`,
        })}
      />
    );
  }

  return (
    <Text
      variant="sm"
      fw={400}
      c={value ? 'gray.9' : 'gray.5'}
      p="02"
      pt={0}
      w="100%"
      sx={theme => ({
        ':hover': {
          borderColor: theme.colors.gray[1],
          borderRadius: theme.spacing['03'],
        },
        whiteSpace: value ? 'normal' : 'pre-wrap',
        border: `1px solid transparent`,
      })}
      onClick={() => {
        setFocus(true);
        setTimeout(() => {
          if (textareaRef.current) {
            textareaRef.current.focus();
            textareaRef.current.select();
          }
        }, 0);
      }}
    >
      <Markdown>{value || t('placeholder')}</Markdown>
    </Text>
  );
};
