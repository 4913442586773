import { type ReactNode } from 'react';

import {
  Box,
  Divider,
  Group,
  Skeleton,
  Stack,
  Text,
  Transition,
} from '@mantine/core';
import type { DividerProps } from '@mantine/core';
import { useHover } from '@mantine/hooks';

import { IconArrowRight } from '@tabler/icons-react';
import { Link } from 'react-router-dom';

import { RenewalTag } from '../../../../shared/components/UI/RenewalTag/RenewalTag';
import TenderStatusBadge from '../../../../shared/components/UI/TenderStatusBadge/TenderStatusBadge';
import { TenderTitle } from '../../../../shared/components/UI/TenderTitle/TenderTitle';

import * as KeyInfoTooltip from '../../../../shared/components/KeyInfoTooltip';
import { CPVRow } from '../../../../shared/components/CPVRow';
import { RenewalActionButtons } from '../../../../shared/components/RenewalActionButton';
import { useUrlHash } from '../../../../shared/contexts/UrlHash.provider';
import type {
  DecisionRenewalStatus,
  DecisionStatus,
} from '../../../../shared/entities/Interaction';
import { formatCurrency } from '../../../../shared/utils/curencies';
import { formatDateAsText } from '../../../../shared/utils/dates';
import { UrlHashBuilder } from '../../../../shared/utils/navigation/UrlHashBuilder';
import CardCorner from '../../assets/card_corner.svg';
import type { TenderWithTransition } from '../forms/hooks/useSearchTenders.hook';
import { TenderPreviewCardActionElement } from './PreviewCardActionElement';
import { TenderDataDisplay } from './TenderDataDisplay';
import { getBackgroundFromDecisionStatus } from './utils';

type TenderPreviewCardProps = {
  tender: TenderWithTransition;
  onTenderDecision: (
    status: DecisionStatus | DecisionRenewalStatus,
    reason?: string,
  ) => void;
};

export function TenderPreviewCard({
  tender,
  onTenderDecision,
}: TenderPreviewCardProps) {
  const decisionElement = (
    <TenderPreviewCardActionElement
      decisionStatus={tender.interaction?.decisionStatus}
      onTenderDecision={onTenderDecision}
      owner={tender.interaction?.owner}
    />
  );

  const renewalDecisionElement = (
    <RenewalActionButtons
      onTenderDecision={onTenderDecision}
      decisionRenewalStatus={tender.interaction?.decisionRenewalStatus}
      owner={tender.interaction?.owner}
    />
  );

  const actionElements = tender.isRenewal
    ? renewalDecisionElement
    : decisionElement;

  return (
    <Transition
      mounted={tender.mounted ?? true}
      transition={tender.transitionStyle || 'fade'}
      exitDuration={450}
      timingFunction="ease"
    >
      {style => (
        <Container
          style={style}
          decisionStatus={
            tender.isRenewal
              ? tender.interaction?.decisionRenewalStatus
              : tender.interaction?.decisionStatus
          }
          tenderId={tender.id}
        >
          <Group noWrap align="flex-start" position="apart">
            <Stack spacing="03">
              <Header tender={tender} />
              <DataRow tender={tender} />
              <CPVRow cpvs={tender.cpvs} />
              {tender.isRenewal && <RenewalTag />}
            </Stack>
            {actionElements}
          </Group>
        </Container>
      )}
    </Transition>
  );
}

type ContainerProps = {
  style: React.CSSProperties;
  tenderId: number;
  decisionStatus?: DecisionStatus | DecisionRenewalStatus;
  children: ReactNode;
};

const Container = ({
  style,
  decisionStatus,
  tenderId,
  children,
}: ContainerProps) => {
  const { hovered, ref } = useHover<HTMLAnchorElement>();
  const { getRedirectUrl } = useUrlHash();

  const background = getBackgroundFromDecisionStatus(decisionStatus);

  return (
    <Box
      style={style}
      ref={ref}
      component={Link}
      p="05"
      sx={theme => ({
        borderRadius: theme.radius.md,
        border: `1px solid ${theme.colors.gray[2]}`,
        boxShadow: theme.shadows.sm,
        background,
        position: 'relative',
        textDecoration: 'none',
        color: 'inherit',
        ':hover': {
          cursor: 'pointer',
          boxShadow: theme.shadows.lg,
        },
      })}
      to={getRedirectUrl(
        `/notices/${tenderId}`,
        new UrlHashBuilder().addLocationData().build(),
      )}
    >
      {children}
      <Box
        sx={{
          position: 'absolute',
          bottom: 0,
          right: 0,
          paddingTop: '14px',
          paddingLeft: '20px',
          width: '53px',
          height: '41px',
          overflow: 'hidden',
          '&::after': {
            content: '""',
            position: 'absolute',
            bottom: 0,
            right: 0,
            width: '53px',
            height: '41px',
            backgroundImage: `url(${CardCorner})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            transform: hovered
              ? 'scale(1.4) rotate(-20deg) translateX(-4px) translateY(8px)'
              : '',
            transition: 'transform 0.3s',
          },
        }}
      >
        <IconArrowRight size={16} stroke={1.5} />
      </Box>
    </Box>
  );
};

type HeaderProps = {
  tender: TenderWithTransition;
};

const Header = ({ tender }: HeaderProps) => {
  return (
    <Group noWrap position="apart" align="flex-start" w="100%">
      <TenderTitle
        buyerOriginalName={tender.buyer.originalName}
        buyerPostalCode={tender.buyer.postalCode}
        tenderTitle={tender.title}
        logoURL={tender.buyer.logoURL}
        size="md"
      />
    </Group>
  );
};

function DataRow({ tender }: { tender: TenderWithTransition }) {
  const publicationDate = formatDateAsText(
    tender.publicationDate,
    false,
    false,
  );
  const responseDeadline = tender.responseDeadline
    ? formatDateAsText(tender.responseDeadline, false, false)
    : '-';

  const dividerProps: DividerProps = {
    orientation: 'vertical',
    h: '07',
    my: 'auto',
    color: 'gray.2',
  };

  return (
    <Group noWrap spacing="05">
      {/* Dates */}
      <TenderDataDisplay
        statTitle={
          <Group noWrap spacing="02">
            <Text variant="xs" fw="500" c="gray.6">
              Dates
            </Text>
            <TenderStatusBadge status={tender.status} />
          </Group>
        }
        statValue={
          <Group noWrap spacing={4} c="gray.5">
            <Text variant="md" fw={400} c="gray.9">
              {publicationDate}
            </Text>
            <IconArrowRight size={16} />
            <Text variant="md" fw={400} c="gray.9">
              {responseDeadline}
            </Text>
          </Group>
        }
      />
      <Divider {...dividerProps} />

      {/* Amout */}
      <TenderDataDisplay
        statTitle="Montant"
        tooltipContent={<KeyInfoTooltip.Amount />}
        statValue={formatCurrency(tender.estimatedValueInEur) || '-'}
        isLmmEnriched={tender.isEstimatedValueInEurLLMEnriched}
      />
      <Divider {...dividerProps} />

      {/* Duration */}
      <TenderDataDisplay
        statTitle="Durée"
        tooltipContent={<KeyInfoTooltip.Duration />}
        statValue={
          tender.durationInMonth ? tender.durationInMonth + ' mois' : '-'
        }
        isLmmEnriched={tender.isDurationInMonthLLMEnriched}
      />
      <Divider {...dividerProps} />

      {/* Location */}
      <TenderDataDisplay
        statTitle="Lieu d'exc."
        statValue={tender.executionLocation?.city || '-'}
      />
      <Divider {...dividerProps} />

      {/* Lots */}
      <TenderDataDisplay
        statTitle="Lots"
        statValue={tender.lots?.length || '-'}
      />
    </Group>
  );
}

export function TenderPreviewSkeletton() {
  return (
    <Stack spacing={24}>
      <Skeleton p={24} maw="1500px" h="250px" radius={8}></Skeleton>
      <Skeleton p={24} maw="1500px" h="250px" radius={8}></Skeleton>
    </Stack>
  );
}
