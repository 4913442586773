import type { SummaryCriterion } from '../../../entities/SummaryCriterion';
import type { SummaryCriterionAnswer } from '../../../entities/SummaryCriterionAnswer';
import { magellanClient } from '../../../infra/axios';
import type {
  CanGenerateSummarySheetResponseDTO,
  CreateSummaryCriterionRequestDTO,
  GetAllSummaryCriteriaResponseDTO,
  GetSummaryCriteriaAnswersResponseDTO,
} from './dto';

export async function getSummaryCriteria(): Promise<GetAllSummaryCriteriaResponseDTO> {
  const response =
    await magellanClient.get<GetAllSummaryCriteriaResponseDTO>(
      `/summaryCriteria`,
    );

  return response.data;
}

export async function getSummaryCriterionAnswers(
  tenderId: number,
): Promise<GetSummaryCriteriaAnswersResponseDTO> {
  const response =
    await magellanClient.get<GetSummaryCriteriaAnswersResponseDTO>(
      `/tenders/${tenderId}/summaryCriteriaAnswers`,
    );
  return response.data;
}

export async function getSummaryCriterionDetail(
  tenderId: number,
  criterionAnswerId: number,
): Promise<SummaryCriterionAnswer> {
  const response = await magellanClient.get<SummaryCriterionAnswer>(
    `/tenders/${tenderId}/summaryCriteriaAnswers/${criterionAnswerId}`,
  );
  return response.data;
}

export async function replaceAllSummaryCriteria(
  criteria: CreateSummaryCriterionRequestDTO[],
): Promise<SummaryCriterion[]> {
  const response = await magellanClient.post<SummaryCriterion[]>(
    `/summaryCriteria/replaceAll`,
    { criteria },
  );
  return response.data;
}

export async function updateSummaryCriterionAnswer(
  tenderId: number,
  criterionId: number,
  manualAnswer: string | null,
): Promise<SummaryCriterionAnswer> {
  const response = await magellanClient.put<SummaryCriterionAnswer>(
    `/tenders/${tenderId}/summaryCriteriaAnswers/${criterionId}`,
    {
      manualAnswer,
    },
  );

  return response.data;
}

export async function getCanGenerateSummarySheet(
  tenderId: number,
): Promise<CanGenerateSummarySheetResponseDTO> {
  const response = await magellanClient.get<CanGenerateSummarySheetResponseDTO>(
    `/tenders/${tenderId}/summarySheets/canGenerate`,
  );

  return response.data;
}

export async function generateSummarySheet(
  tenderId: number,
  criteria?: CreateSummaryCriterionRequestDTO[],
): Promise<CanGenerateSummarySheetResponseDTO> {
  const response =
    await magellanClient.post<CanGenerateSummarySheetResponseDTO>(
      `/tenders/${tenderId}/summarySheets/generate`,
      {
        criteria,
      },
    );

  return response.data;
}

export async function generateSummarySheetWithOnTheFlyQuestion(
  tenderId: number,
  question: string,
): Promise<void> {
  const response = await magellanClient.post<void>(
    `/tenders/${tenderId}/summarySheets/generateOnTheFlyQuestion`,
    {
      question,
    },
  );
  return response.data;
}
