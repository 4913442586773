import { useEffect, useRef, useState } from 'react';

import { Group, useMantineTheme } from '@mantine/core';
import { RichTextEditor as MantineRichTextEditor } from '@mantine/tiptap';

import Document from '@tiptap/extension-document';
import Mention from '@tiptap/extension-mention';
import Paragraph from '@tiptap/extension-paragraph';
import Placeholder from '@tiptap/extension-placeholder';
import Text from '@tiptap/extension-text';
import type { Editor } from '@tiptap/react';
import { useEditor } from '@tiptap/react';

import type { RichTextJson } from '../../../entities/Comment';
import type { User } from '../../../entities/User';
import { mentionSuggestionOptions } from './mentionSuggestionOptions';

export type RichTextEditorVariant = 'subtle' | 'default';
export type RightSectionProps = {
  editor: Editor | null;
  variant: RichTextEditorVariant;
  handleSave?: (content: RichTextJson) => void;
};

export type RichTextEditorProps = {
  RightSection?: React.ComponentType<RightSectionProps>;
  editable?: boolean;
  onSubmit?: (content: RichTextJson) => void;
  initialContent?: RichTextJson;
  placeholder?: string;
  showBorderOnFocus?: boolean;
  variant?: RichTextEditorVariant;
  users: User[];
};

export function RichTextEditor({
  RightSection,
  editable = true,
  variant = 'default',
  onSubmit,
  initialContent,
  placeholder,
  showBorderOnFocus = false,
  users,
}: RichTextEditorProps) {
  const theme = useMantineTheme();
  const [isShiftPressed, setIsShiftPressed] = useState(false);
  const isShiftPressedRef = useRef(isShiftPressed);

  const editor = useEditor(
    {
      extensions: [
        Document.extend({
          addKeyboardShortcuts() {
            return {
              Enter: ({ editor }) => {
                if (isShiftPressedRef.current) {
                  return false;
                }
                onSubmit && onSubmit(editor.getJSON());
                return this.editor.chain().clearContent().blur().run();
              },
              'Shift-Enter': () => {
                return this.editor.commands.enter();
              },
              Escape: ({ editor }) => {
                if (initialContent) {
                  const res = editor
                    .chain()
                    .setContent(initialContent)
                    .blur()
                    .run();
                  onSubmit && onSubmit(editor.getJSON());
                  return res;
                }
                return false;
              },
            };
          },
        }),
        Placeholder.configure({
          placeholder,
        }),
        Text,
        Paragraph,
        Mention.configure({
          HTMLAttributes: {
            class: 'tengo-tiptap-mention',
          },
          deleteTriggerWithBackspace: true,
          suggestion: mentionSuggestionOptions(users),
        }),
      ],
      editable,
      content: initialContent,
      editorProps: {
        handleDOMEvents: {
          keydown: (_view, event) => {
            if (event.key === 'Shift') {
              setIsShiftPressed(true);
            }
          },
          keyup: (_view, event) => {
            if (event.key === 'Shift') {
              setIsShiftPressed(false);
            }
          },
        },
      },
    },
    [initialContent],
  );

  // Keep the ref updated with the current state
  useEffect(() => {
    isShiftPressedRef.current = isShiftPressed;
  }, [isShiftPressed]);

  // Focus and select all text when the editor when editing an existing comment
  useEffect(() => {
    if (initialContent && editor) {
      editor.chain().focus().selectAll().run();
    }
  }, [editor, initialContent]);

  const isFocused = editor?.isFocused;
  const defaultBorder = editor?.isFocused
    ? `1.5px solid ${theme.colors.primary[6]}`
    : `1.5px solid ${theme.colors.gray[2]}`;
  const showBorder =
    (showBorderOnFocus && isFocused) || (initialContent && editable);
  const subtleBorder = showBorder
    ? `1.5px solid ${theme.colors.primary[6]}`
    : `1.5px solid transparent`;

  return (
    <MantineRichTextEditor
      editor={editor}
      maw="unset!important"
      sx={theme => ({
        flexGrow: 1,
        borderRadius: theme.radius.md,
        border: variant === 'subtle' ? subtleBorder : defaultBorder,
      })}
      styles={theme => ({
        typographyStylesProvider: {
          maxWidth: 'unset !important',
          flexGrow: 1,
          '& .tiptap': {
            padding: '0px!important',
            fontSize: theme.fontSizes.sm,
          },
          '& .tengo-tiptap-mention': {
            color: theme.colors.primary[6],
            fontWeight: 600,
            fontSize: theme.fontSizes.sm,
          },
          '& .tiptap p.is-editor-empty:first-of-type::before': {
            color: theme.colors.gray[4],
            fontWeight: 400,
            fontSize: theme.fontSizes.sm,
          },
        },
      })}
    >
      <Group
        noWrap
        sx={{ flexDirection: 'row-reverse' }}
        p={editable ? '02' : '0px'}
        spacing="00"
        align="flex-end"
        grow
      >
        {RightSection && (
          <RightSection
            editor={editor}
            variant={variant}
            handleSave={onSubmit}
          />
        )}
        <MantineRichTextEditor.Content
          sx={{
            flexGrow: 1,
            wordBreak: 'break-word',
          }}
        />
      </Group>
    </MantineRichTextEditor>
  );
}
