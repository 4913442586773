import { useState } from 'react';

import { useDragAndDrop } from '@formkit/drag-and-drop/react';

import type { SummaryCriterion } from '../../../../../shared/entities/SummaryCriterion';
import { useGetCriteriaQuery } from './useGetCriteriaQuery.hook';

export function useSummarySheetCriteria(
  clearValidationErrorForCriterion: (criterion: SummaryCriterion) => void,
) {
  const criteriaQuery = useGetCriteriaQuery();
  const [isDragging, setIsDragging] = useState(false);
  const [dragContainerRef, criteria, setCriteria] = useDragAndDrop<
    HTMLDivElement,
    SummaryCriterion
  >(criteriaQuery.data?.criteria || [], {
    dragHandle: '#drag-handle',
    draggable: el => {
      return el.id !== 'no-drag';
    },
    onDragstart: () => {
      setIsDragging(true);
    },
    onDragend: () => {
      setIsDragging(false);
    },
    scrollBehavior: { x: 0, y: 0.3, scrollOutside: true },
  });

  const handleUpdateCriterion = (criterion: SummaryCriterion) => {
    setCriteria(prevCriteria =>
      prevCriteria.map(c => (c.id === criterion.id ? criterion : c)),
    );
    clearValidationErrorForCriterion(criterion);
  };

  const handleDeleteCriterion = (criterion: SummaryCriterion) => {
    setCriteria(prevCriteria =>
      prevCriteria.filter(c => c.id !== criterion.id),
    );
  };

  const handleNewCriterion = () => {
    const newCriterionId = Math.random();
    const newCriterion: SummaryCriterion = {
      id: newCriterionId,
      question: '',
      title: '',
      examples: '',
      llmAnswerType: 'LONG_ANSWER',
      companyId: 0,
    };
    setCriteria(prevCriteria => [...prevCriteria, newCriterion]);
    // Wait for the DOM to update and focus on the new criterion input
    setTimeout(() => {
      const input = document.querySelector<HTMLInputElement>(
        `[data-criterion-id="${newCriterionId}"] input`,
      );
      input?.focus();
    }, 0);
  };

  const checkChanges = () => {
    const originalCriteria = criteriaQuery.data?.criteria || [];
    if (criteria.length !== originalCriteria.length) return true;

    return criteria.some((criterion, index) => {
      const original = originalCriteria[index];
      return (
        criterion.title !== original.title ||
        criterion.question !== original.question ||
        criterion.examples !== original.examples ||
        criterion.llmAnswerType !== original.llmAnswerType
      );
    });
  };

  return {
    dragContainerRef,
    isDragging,
    criteria,
    hasChanges: checkChanges(),
    handleUpdateCriterion,
    handleDeleteCriterion,
    handleNewCriterion,
  };
}
