import type { FeatureFlag } from '../entities/FeatureFlags';
import { useActiveUserQuery } from './useActiveUserQuery.hook';

export function useFeatureFlags(feature: FeatureFlag) {
  const activeUserQuery = useActiveUserQuery();
  const featureFlags = activeUserQuery.data.featureFlags;

  const isFeatureEnabled = (feature: FeatureFlag) => {
    return featureFlags?.[feature] ?? false;
  };

  return isFeatureEnabled(feature);
}
