import type { ReactNode } from 'react';
import { createContext, useContext, useEffect, useRef, useState } from 'react';

import { Drawer, Group } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';

import { useAutoAnimate } from '@formkit/auto-animate/react';

import { NAVBAR_HEIGHT } from '../components/UI/Navbar/Navbar';
import type { SidepanelProps } from '../components/UI/Sidepanel/Sidepanel';
import { Sidepanel } from '../components/UI/Sidepanel/Sidepanel';

interface SidepanelContextType {
  togglePanel: (content: SidepanelProps) => void;
  closePanel: (id: string) => void;
  updatePanel: (content: SidepanelProps) => void;
}

type MinimalSidepanelProps = Omit<SidepanelProps, 'id' | 'onClose'>;

const SidepanelContext = createContext<SidepanelContextType | undefined>(
  undefined,
);

export function SidepanelProvider({ children }: { children: ReactNode }) {
  const [opened, { open, close }] = useDisclosure(false);
  const [panels, setPanels] = useState<SidepanelProps[]>([]);
  const [parent] = useAutoAnimate();

  const togglePanel = (newPanel: SidepanelProps) => {
    const existingPanel = panels.find(panel => panel.id === newPanel.id);
    if (existingPanel) {
      return closePanel(existingPanel.id);
    }
    setPanels(prev => [...prev, newPanel]);
    if (!opened) {
      open();
    }
  };

  const closePanel = (id: string) => {
    setPanels(prev => prev.filter(panel => panel.id !== id));
  };

  const closeAllPanels = () => {
    panels.forEach(panel => panel.onClose());
    setPanels([]);
    close();
  };

  const updatePanel = (updatedPanel: SidepanelProps) => {
    setPanels(prev =>
      prev.map(panel => (panel.id === updatedPanel.id ? updatedPanel : panel)),
    );
  };

  useEffect(() => {
    if (!panels.length && opened) {
      close();
    }
  }, [close, opened, panels]);

  return (
    <SidepanelContext.Provider value={{ togglePanel, closePanel, updatePanel }}>
      {children}
      <Drawer
        opened={opened}
        position="right"
        onClose={closeAllPanels}
        size="fit-content"
        withCloseButton={false}
        overlayProps={{ opacity: 0.5 }}
        p={0}
        styles={{
          body: {
            padding: 0,
            height: '100%',
            overflow: 'hidden',
          },
          inner: {
            top: NAVBAR_HEIGHT,
          },
          overlay: {
            top: NAVBAR_HEIGHT,
          },
        }}
      >
        <Group ref={parent} maw="100%" noWrap h="100%" spacing={0}>
          {panels.map(panel => (
            <Sidepanel {...panel} key={panel.id} />
          ))}
        </Group>
      </Drawer>
    </SidepanelContext.Provider>
  );
}

export function useSidepanel({ onClose }: { onClose?: () => void } = {}) {
  const context = useContext(SidepanelContext);
  if (!context) {
    throw new Error('useSidepanel must be used within a SidepanelProvider');
  }
  // using ref to get consistent ID across renders
  const idRef = useRef(Math.random().toString(36).substring(7));

  const id = idRef.current;
  const closePanel = () => {
    onClose?.();
    context.closePanel(id);
  };

  return {
    togglePanel: (content: MinimalSidepanelProps) =>
      context.togglePanel({ ...content, id, onClose: closePanel }),
    updatePanel: (content: MinimalSidepanelProps) =>
      context.updatePanel({ ...content, id, onClose: closePanel }),
    closePanel,
  };
}
