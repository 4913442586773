import { useSuspenseQuery } from '@tanstack/react-query';

import { getSummaryCriteria } from '../../../../../shared/api/magellan/summarySheet';
import { useActiveUserQuery } from '../../../../../shared/hooks/useActiveUserQuery.hook';

export function useGetCriteriaQuery() {
  const {
    data: { user: activeUser },
  } = useActiveUserQuery();

  const queryKey = [getSummaryCriteria.name, activeUser.id];
  return useSuspenseQuery({
    queryKey,
    queryFn: getSummaryCriteria,
  });
}
