export function DocX({
  fileUrl,
  initialPage = 1,
}: {
  fileUrl: string;
  initialPage?: number;
}) {
  const embedUrl = `https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(fileUrl)}&wdStartOn=${initialPage}`;

  return (
    <iframe
      src={embedUrl}
      width="100%"
      height="100%"
      title="Office Document Viewer"
    />
  );
}
